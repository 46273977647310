import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetSignInResponse from 'services/httpClient/responses/auth/IGetSignInResponse';

import { IApplicationState } from 'store/rootReducers';

import { authActions } from '../index';

function* setToken({ payload }: PayloadAction<IApplicationState>) {
  try {
    if (!payload) {
      return;
    }

    const { authorization } = payload.auth;

    if (authorization?.token) {
      httpClient.defaults.headers.common.Authorization = `Bearer ${authorization.token}`;

      const { data }: AxiosResponse<IGetSignInResponse> = yield call(
        httpClient.get,
        `sessions/admins`,
      );
      yield put(authActions.setProfile(data));
    }
  } catch (err: any) {
    helpers.errorHandling(err);
  }
}

export default setToken;
