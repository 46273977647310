import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

export interface IChangePasswordRequestAction {
  data: {
    newPassword: string;
    oldPassword: string;
  };
  functions: {
    closeForm: () => void;
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function changePasswordRequest(
  draft: Draft<IAuthData>,
  _: PayloadAction<IChangePasswordRequestAction>,
) {
  draft.changePassword.config.isLoading = true;
}

export default changePasswordRequest;
