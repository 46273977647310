import styled from 'styled-components';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Container = styled.div`
  background-color: ${colors.white100};
  border: 1px solid ${colors.gray100};
  border-radius: 1rem;
  bottom: 3rem;
  padding: 2rem;
  position: fixed;
  right: 3rem;
  width: 29rem;
`;

export const Content = styled.div`
  position: relative;
`;

export const Title = styled.h4`
  color: ${colors.cyan900};
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const CloseButton = styled.button`
  background-color: ${colors.transparent};
  border: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const Message = styled.p`
  font-size: 0.875rem;
`;

export const LoadingBar = styled.div`
  border: 1px solid ${colors.gray100};
  border-radius: 0.325rem;
  height: 1.25rem;
  margin-top: 1rem;
  overflow: hidden;
  padding: 0.25rem;
  position: relative;
  width: 100%;
`;

export const Bar = styled.div`
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${animations.loadingBar};
  animation-timing-function: ease-in-out;
  background-color: ${colors.cyan900};
  border-radius: 0.16rem;
  height: 100%;
  transform: all 0.3s ease-in;
  width: 8rem;
`;
