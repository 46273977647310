import { IconBaseProps } from 'react-icons';

import {
  NavigationItem,
  NavigationItemIcon,
  NavigationItemName,
} from './styles';

interface IComponentAsideMenuItemProps {
  icon: React.ComponentType<IconBaseProps>;
  isSelected?: boolean;
  name: string;
  onClick?: () => void;
}

const ComponentAsideMenuItem = ({
  icon: Icon,
  isSelected,
  name,
  onClick,
}: IComponentAsideMenuItemProps): JSX.Element => {
  return (
    <NavigationItem onClick={onClick} selected={isSelected}>
      <NavigationItemIcon>
        <Icon size={22} />
      </NavigationItemIcon>
      <NavigationItemName>{name}</NavigationItemName>
    </NavigationItem>
  );
};

export default ComponentAsideMenuItem;
