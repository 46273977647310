import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FiLock } from 'react-icons/fi';

import { Formik } from 'formik';
import helpers from 'helpers';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import colors from 'styles/colors';
import Forms from 'styles/forms';

import { Actions, Message } from './styles';

export interface IComponentChangePasswordRefProps {
  open(): void;
}

interface IComponentChangePasswordFormData {
  confirmNewPassword: string;
  newPassword: string;
  oldPassword: string;
}

const initialValues: IComponentChangePasswordFormData = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const componentChangePasswordValidateSchema = Yup.object().shape({
  oldPassword: Yup.string().trim().required('Informe a senhal atual'),
  newPassword: Yup.string().trim().required('Informe a nova senha'),
  confirmNewPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], 'Senhas são diferentes')
    .required('Informe a confirmação da nova senha'),
});

const ComponentChangePassword: React.ForwardRefRenderFunction<
  IComponentChangePasswordRefProps
> = (_, ref) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(authSelectors.changePasswordIsLoading);

  const [hideModal, setHideModal] = useState<boolean>(false);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const openModal = useCallback(() => {
    setHideModal(false);
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleChangeUpdatePassword = useCallback(
    (data: IComponentChangePasswordFormData) => {
      reduxDispatch(
        authActions.changePasswordRequest({
          data: {
            newPassword: data.newPassword,
            oldPassword: data.oldPassword,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            closeForm: () => {
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, reduxDispatch, toast],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''}>
        <Title>Alterar senha</Title>

        <Message>Preencha os campos abaixo para alterar sua senha</Message>

        <Formik
          initialValues={initialValues}
          onSubmit={handleChangeUpdatePassword}
          validateOnChange={false}
          validationSchema={componentChangePasswordValidateSchema}
        >
          {({ errors, handleChange, values }) => (
            <Forms.Content>
              <Forms.Grid>
                <ComponentInputPassword
                  errorMessage={errors.oldPassword}
                  hasError={!!errors.oldPassword}
                  icon={FiLock}
                  onChange={handleChange('oldPassword')}
                  placeholder="Senha atual"
                  value={values.oldPassword}
                />

                <ComponentInputPassword
                  errorMessage={errors.newPassword}
                  hasError={!!errors.newPassword}
                  icon={FiLock}
                  onChange={handleChange('newPassword')}
                  placeholder="Nova senha"
                  value={values.newPassword}
                />

                <ComponentInputPassword
                  errorMessage={errors.confirmNewPassword}
                  hasError={!!errors.confirmNewPassword}
                  icon={FiLock}
                  onChange={handleChange('confirmNewPassword')}
                  placeholder="Confirmar nova senha"
                  value={values.confirmNewPassword}
                />
              </Forms.Grid>
              <Actions>
                <ComponentButtonBase
                  backgroundColor={colors.red500}
                  disabled={isLoading}
                  onClick={closeModal}
                  type="button"
                >
                  Cancelar
                </ComponentButtonBase>
                <ComponentButtonBase
                  backgroundColor={colors.orange700}
                  disabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
                >
                  Confirmar
                </ComponentButtonBase>
              </Actions>
            </Forms.Content>
          )}
        </Formik>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ComponentChangePassword);
