export default {
  background: '#F4F1F1',
  backgroundDark: '#d9dadb',
  boxShadow: 'rgba(0, 0, 0, 0.35)',

  black900: '#000000',

  blue500: '#3b5998',

  cyan900: '#37AA9C',

  gray100: '#DEDEDE',
  gray200: '#CCCCCC',
  gray300: '#888888',
  gray400: '#777777',
  gray500: '#666666',
  gray600: '#6C7A89',
  gray900: '#354649',

  green900: '#33942f',

  lightGreen900: '#25d366',

  orange700: '#f7ae1b',
  orange900: '#de9910',

  red500: '#e57878',

  white100: '#F8F8F8',
  white900: '#FFFFFF',

  transparent: 'transparent',
};
