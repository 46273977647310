import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

export interface IForgotPasswordRequestAction {
  data: {
    email: string;
  };
  functions: {
    error: (err: any) => void;
    navigateTo: () => void;
    success: (message: string) => void;
  };
}

function forgotPasswordRequest(
  draft: Draft<IAuthData>,
  _: PayloadAction<IForgotPasswordRequestAction>,
) {
  draft.forgotPassword.config.isLoading = true;
}

export default forgotPasswordRequest;
