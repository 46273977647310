import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import EUserTypes from 'enums/userTypes';
import { FormikProps } from 'formik';
import helpers from 'helpers';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import IUser from 'models/User';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import { userActions } from 'store/slices/user';
import userSelectors from 'store/slices/user/selectors';

import UserForm, { IUserFormData, userTypeOptions } from '../Form';

export interface IUserEditRefProps {
  open(data: IUser): void;
}

const UserEdit: React.ForwardRefRenderFunction<IUserEditRefProps> = (
  _,
  ref,
) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(userSelectors.editIsLoading);

  const [hideModal, setHideModal] = useState<boolean>(false);
  const [userValues, setUserValues] = useState<IUser>({} as IUser);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const userFormRef = useRef<FormikProps<IUserFormData>>(null);

  const openModal = useCallback((data: IUser) => {
    setHideModal(false);
    setUserValues(data);
    componentModalBaseRef.current?.open();

    setTimeout(() => {
      userFormRef.current?.setValues({
        name: data.name,
        email: data.email,
        phone: data.phone,
        type: userTypeOptions.find(
          userTypeOption => userTypeOption.value === data.type,
        ) as IComponentSelectProps<EUserTypes>,
      });
    }, 250);
  }, []);
  const closeModal = useCallback(() => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleEditUser = useCallback(
    async (data: IUserFormData) => {
      userFormRef.current?.setErrors({});
      reduxDispatch(
        userActions.editRequest({
          data: {
            id: userValues.id,
            name: data.name,
            email: data.email,
            phone: data.phone,
            type: data.type.value,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            closeForm: () => {
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, reduxDispatch, toast, userValues.id],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''}>
        <Title>Editar usuário</Title>
        <UserForm
          handleClose={closeModal}
          isLoading={isLoading}
          onSubmit={handleEditUser}
          ref={userFormRef}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(UserEdit);
