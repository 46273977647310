import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FormikProps } from 'formik';
import helpers from 'helpers';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import { userActions } from 'store/slices/user';
import userSelectors from 'store/slices/user/selectors';

import UserForm, { IUserFormData } from '../Form';

export interface IUserCreateRefProps {
  close(): void;
  open(): void;
}

interface IUserCreateProps {
  reloadUsers(): void;
}

const UserCreate: React.ForwardRefRenderFunction<
  IUserCreateRefProps,
  IUserCreateProps
> = ({ reloadUsers }, ref) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(userSelectors.createIsLoading);

  const [hideModal, setHideModal] = useState<boolean>(false);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const userFormRef = useRef<FormikProps<IUserFormData>>(null);

  const openModal = useCallback(() => {
    setHideModal(false);
    componentModalBaseRef.current?.open();
  }, []);
  const closeModal = useCallback(() => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleCreateUser = useCallback(
    async (data: IUserFormData) => {
      userFormRef.current?.setErrors({});
      reduxDispatch(
        userActions.createRequest({
          data: {
            name: data.name,
            email: data.email,
            phone: data.phone,
            type: data.type.value,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            closeForm: () => {
              reloadUsers();
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, reduxDispatch, reloadUsers, toast],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''}>
        <Title>Novo usuário</Title>
        <UserForm
          handleClose={closeModal}
          isLoading={isLoading}
          onSubmit={handleCreateUser}
          ref={userFormRef}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(UserCreate);
