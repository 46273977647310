import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { FormikProps } from 'formik';
import helpers from 'helpers';
import IProduct from 'models/Product';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentHeader from 'components/Header';
import ComponentAlert, {
  IComponentAlertRefProps,
} from 'components/modal/Alert';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';

import colors from 'styles/colors';
import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import ProductCreate, { IProductCreateRefProps } from '../Create';
import ProductEdit, { IProductEditRefProps } from '../Edit';
import ProductFilter, { IProductFilterData } from './Filter';
import ProductItem from './Item';

const ProductList = (): JSX.Element => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();
  const navigate = useNavigate();
  const products = useReduxSelector(productSelectors.getAllList);
  const pagination = useReduxSelector(productSelectors.getAllPagination);
  const isLoading = useReduxSelector(productSelectors.getAllIsLoading);
  const emptyMessage = useReduxSelector(productSelectors.getAllEmptyMessage);
  const errorMessage = useReduxSelector(productSelectors.getAllErrorMessage);
  const changeStatusIsLoading = useReduxSelector(
    productSelectors.changeStatusIsLoading,
  );
  const removeIsLoading = useReduxSelector(productSelectors.removeIsLoading);

  const productFilterRef = useRef<FormikProps<IProductFilterData>>(null);
  const productCreateRef = useRef<IProductCreateRefProps>(null);
  const productEditRef = useRef<IProductEditRefProps>(null);
  const componentChangeStatusAlertRef = useRef<IComponentAlertRefProps>(null);
  const componentRemoveAlertRef = useRef<IComponentAlertRefProps>(null);

  const handleLoadProducts = useCallback(() => {
    reduxDispatch(
      productActions.getAllRequest({
        data: {
          page: 1,
        },
        functions: {
          error(err) {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handlePagination = useCallback(
    page => {
      reduxDispatch(
        productActions.getAllRequest({
          data: {
            page,
            description: productFilterRef.current?.values.description,
            ean: productFilterRef.current?.values.ean,
            sku: productFilterRef.current?.values.sku,
            manufacturer: productFilterRef.current?.values.manufacturer,
          },
          functions: {
            error(err) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleFilter = useCallback(
    async (data: IProductFilterData) => {
      reduxDispatch(
        productActions.getAllRequest({
          data: {
            page: 1,
            description: data.description,
            ean: data.ean,
            sku: data.sku,
            manufacturer: data.manufacturer,
          },
          functions: {
            error(err: any) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleClearFilter = useCallback(() => {
    productFilterRef.current?.resetForm();
    productFilterRef.current?.setErrors({});
    reduxDispatch(
      productActions.getAllRequest({
        data: {
          page: 1,
        },
        functions: {
          error(err: any) {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handleOpenProductCreate = useCallback(() => {
    productCreateRef.current?.open();
  }, []);

  const handleOpenProductEdit = useCallback((data: IProduct) => {
    productEditRef.current?.open(data);
  }, []);

  const handleOpenChangeStatusAlert = useCallback((data: IProduct) => {
    componentChangeStatusAlertRef.current?.open({
      id: data.id,
      name: data.name,
      isActive: data.isActive,
    });
  }, []);

  const handleChangeProductStatus = useCallback(
    (data: IProduct) => {
      reduxDispatch(
        productActions.changeStatusRequest({
          data: {
            id: data.id,
            isActive: data.isActive,
          },
          functions: {
            success: (message: string) => {
              componentChangeStatusAlertRef.current?.close();
              toast.show({
                type: 'success',
                title: message,
              });
            },
            error(err: any) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [reduxDispatch, toast],
  );

  const handleOpenRemoveAlert = useCallback((data: IProduct) => {
    componentRemoveAlertRef.current?.open({
      id: data.id,
      name: data.name,
      remove: true,
    });
  }, []);

  const handleRemoveProduct = useCallback(
    (data: IProduct) => {
      reduxDispatch(
        productActions.removeRequest({
          data: {
            id: data.id,
          },
          functions: {
            success: (message: string) => {
              componentRemoveAlertRef.current?.close();
              toast.show({
                type: 'success',
                title: message,
              });
            },
            error(err: any) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [reduxDispatch, toast],
  );

  const openProductImport = useCallback(() => {
    navigate(pages.product.import);
  }, [navigate]);

  const screenWidth = useMemo(() => {
    return window.innerWidth;
  }, []);

  useEffect(() => {
    handleLoadProducts();
  }, [handleLoadProducts]);

  return (
    <Containers.Global>
      <ComponentHeader pageTitle="Produtos" />
      <Containers.Content>
        <General.PageFilterContainer>
          <ProductFilter
            isLoading={isLoading}
            onClear={handleClearFilter}
            onSubmit={handleFilter}
            ref={productFilterRef}
          />

          <General.PageActions
            gridTemplateColumns={screenWidth >= 1000 ? '12rem 10rem' : '10rem'}
          >
            <ComponentIsVisible when={screenWidth >= 1000}>
              <General.PageAction
                backgroundColor={colors.orange900}
                onClick={openProductImport}
              >
                Importar produtos
              </General.PageAction>
            </ComponentIsVisible>
            <General.PageAction onClick={handleOpenProductCreate}>
              Novo produto
            </General.PageAction>
          </General.PageActions>
        </General.PageFilterContainer>

        <Tables.Items>
          <ProductItem.Header />

          <ComponentLoadingList show={isLoading} />

          <ComponentIsVisible when={!isLoading}>
            <ComponentIsVisible when={!!products?.length}>
              {products?.map((product, position) => (
                <ProductItem.Body
                  key={product.id}
                  openChangeStatusAlert={handleOpenChangeStatusAlert}
                  openProductEdit={handleOpenProductEdit}
                  openRemoveAlert={handleOpenRemoveAlert}
                  position={position}
                  product={product}
                />
              ))}
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={!products?.length && !errorMessage}>
            <ComponentEmpty message={emptyMessage} show={!!emptyMessage} />
          </ComponentIsVisible>

          <ComponentIsVisible when={!!errorMessage}>
            <ComponentError
              message={errorMessage}
              onClick={handleLoadProducts}
            />
          </ComponentIsVisible>
        </Tables.Items>

        <ComponentPaginate
          currentPage={pagination.page}
          onPage={handlePagination}
          show={!!products?.length}
          totalPages={pagination.totalPages}
        />

        <ProductCreate
          ref={productCreateRef}
          reloadProducts={handleLoadProducts}
        />
        <ProductEdit ref={productEditRef} />

        <ComponentAlert
          isLoading={changeStatusIsLoading}
          onConfirm={handleChangeProductStatus}
          ref={componentChangeStatusAlertRef}
        />
        <ComponentAlert
          isLoading={removeIsLoading}
          onConfirm={handleRemoveProduct}
          ref={componentRemoveAlertRef}
        />
      </Containers.Content>
    </Containers.Global>
  );
};

export default ProductList;
