import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

interface ISetToggleImportSpreadsheetRemittanceInBackgroundAction {
  value: boolean;
}

function setToggleImportSpreadsheetRemittanceInBackground(
  draft: Draft<IProductData>,
  action: PayloadAction<ISetToggleImportSpreadsheetRemittanceInBackgroundAction>,
) {
  draft.importSpreadsheet.generatingInBackground = action.payload.value;
}

export default setToggleImportSpreadsheetRemittanceInBackground;
