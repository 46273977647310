import { useCallback, useEffect, useRef } from 'react';

import { FormikProps } from 'formik';
import helpers from 'helpers';
import IUser from 'models/User';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentHeader from 'components/Header';
import ComponentAlert, {
  IComponentAlertRefProps,
} from 'components/modal/Alert';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import { userActions } from 'store/slices/user';
import userSelectors from 'store/slices/user/selectors';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import UserChangePassword, {
  IUserChangePasswordRefProps,
} from '../ChangePassword';
import UserCreate, { IUserCreateRefProps } from '../Create';
import UserEdit, { IUserEditRefProps } from '../Edit';
import UserFilter, { IUserFilterData } from './Filter';
import UserItem from './Item';

const UserList = (): JSX.Element => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();
  const users = useReduxSelector(userSelectors.getAllList);
  const pagination = useReduxSelector(userSelectors.getAllPagination);
  const isLoading = useReduxSelector(userSelectors.getAllIsLoading);
  const emptyMessage = useReduxSelector(userSelectors.getAllEmptyMessage);
  const errorMessage = useReduxSelector(userSelectors.getAllErrorMessage);
  const changeStatusIsLoading = useReduxSelector(
    userSelectors.changeStatusIsLoading,
  );

  const userFilterRef = useRef<FormikProps<IUserFilterData>>(null);
  const userCreateRef = useRef<IUserCreateRefProps>(null);
  const userEditRef = useRef<IUserEditRefProps>(null);
  const userChangePasswordRef = useRef<IUserChangePasswordRefProps>(null);
  const componentAlertRef = useRef<IComponentAlertRefProps>(null);

  const handleLoadUsers = useCallback(() => {
    reduxDispatch(
      userActions.getAllRequest({
        data: {
          page: 1,
        },
        functions: {
          error(err) {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handlePagination = useCallback(
    page => {
      reduxDispatch(
        userActions.getAllRequest({
          data: {
            page,
            name: userFilterRef.current?.values.name,
          },
          functions: {
            error(err) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleFilter = useCallback(
    async (data: IUserFilterData) => {
      reduxDispatch(
        userActions.getAllRequest({
          data: {
            page: 1,
            name: data.name,
          },
          functions: {
            error(err: any) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleClearFilter = useCallback(() => {
    userFilterRef.current?.resetForm();
    userFilterRef.current?.setErrors({});
    reduxDispatch(
      userActions.getAllRequest({
        data: {
          page: 1,
        },
        functions: {
          error(err: any) {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handleOpenCreateUser = useCallback(() => {
    userCreateRef.current?.open();
  }, []);

  const handleOpenEditUser = useCallback((data: IUser) => {
    userEditRef.current?.open(data);
  }, []);

  const handleOpenChangePasswordUser = useCallback((data: IUser) => {
    userChangePasswordRef.current?.open({
      id: data.id,
      name: data.name,
    });
  }, []);

  const handleOpenChangeStatusAlert = useCallback((data: IUser) => {
    componentAlertRef.current?.open({
      id: data.id,
      name: data.name,
      isActive: data.isActive,
    });
  }, []);
  const handleChangeUserStatus = useCallback(
    (data: IUser) => {
      reduxDispatch(
        userActions.changeStatusRequest({
          data: {
            id: data.id,
            isActive: data.isActive,
          },
          functions: {
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            error(err: any) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
      componentAlertRef.current?.close();
    },
    [reduxDispatch, toast],
  );

  useEffect(() => {
    handleLoadUsers();
  }, [handleLoadUsers]);

  return (
    <Containers.Global>
      <ComponentHeader pageTitle="Usuários" />
      <Containers.Content>
        <General.PageFilterContainer>
          <UserFilter
            isLoading={isLoading}
            onClear={handleClearFilter}
            onSubmit={handleFilter}
            ref={userFilterRef}
          />

          <General.PageActions gridTemplateColumns=" 11rem">
            <General.PageAction onClick={handleOpenCreateUser}>
              Novo usuário
            </General.PageAction>
          </General.PageActions>
        </General.PageFilterContainer>

        <Tables.Items>
          <UserItem.Header />

          <ComponentLoadingList show={isLoading} />

          <ComponentIsVisible when={!isLoading}>
            <ComponentIsVisible when={!!users?.length}>
              {users?.map((user, position) => (
                <UserItem.Body
                  key={user.id}
                  openChangePassword={handleOpenChangePasswordUser}
                  openChangeStatusAlert={handleOpenChangeStatusAlert}
                  openEdit={handleOpenEditUser}
                  position={position}
                  user={user}
                />
              ))}
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={!users?.length && !errorMessage}>
            <ComponentEmpty message={emptyMessage} show={!!emptyMessage} />
          </ComponentIsVisible>

          <ComponentIsVisible when={!!errorMessage}>
            <ComponentError message={errorMessage} onClick={handleLoadUsers} />
          </ComponentIsVisible>
        </Tables.Items>

        <ComponentPaginate
          currentPage={pagination.page}
          onPage={handlePagination}
          show={!!users?.length}
          totalPages={pagination.totalPages}
        />

        <UserCreate ref={userCreateRef} reloadUsers={handleLoadUsers} />
        <UserEdit ref={userEditRef} />
        <UserChangePassword ref={userChangePasswordRef} />
        <ComponentAlert
          isLoading={changeStatusIsLoading}
          onConfirm={handleChangeUserStatus}
          ref={componentAlertRef}
        />
      </Containers.Content>
    </Containers.Global>
  );
};

export default UserList;
