import { all, takeLatest } from 'redux-saga/effects';

import { manufacturerActions } from '../index';
import changeStatus from './changeStatus';
import create from './create';
import edit from './edit';
import getAll from './getAll';
import getAllToSelect from './getAllToSelect';
import importSpreadsheet from './importSpreadsheet';

const manufacturerSagas = all([
  takeLatest(manufacturerActions.changeStatusRequest, changeStatus),
  takeLatest(manufacturerActions.createRequest, create),
  takeLatest(manufacturerActions.editRequest, edit),
  takeLatest(manufacturerActions.getAllRequest, getAll),
  takeLatest(manufacturerActions.getAllToSelectRequest, getAllToSelect),
  takeLatest(manufacturerActions.importSpreadsheetRequest, importSpreadsheet),
]);

export default manufacturerSagas;
