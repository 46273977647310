import styled from 'styled-components';

import Tables from 'styles/tables';

export const ManufacturerItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 6rem 15rem 8rem repeat(2, 7rem);
  padding: 1.25rem 1rem;

  @media screen and (min-width: 1240px) {
    grid-template-columns: 6rem 1fr 8rem repeat(2, 7rem);
  }
`;

export const ManufacturerItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 6rem 15rem 8rem repeat(2, 7rem);
  padding: 1rem;

  @media screen and (min-width: 1240px) {
    grid-template-columns: 6rem 1fr 8rem repeat(2, 7rem);
  }
`;
