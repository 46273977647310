import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

export interface IGetAllProductSuccessWithoutDataAction {
  message: string;
}
function getAllSuccessWithoutData(
  draft: Draft<IProductData>,
  action: PayloadAction<IGetAllProductSuccessWithoutDataAction>,
) {
  draft.getAll.config.emptyMessage = action.payload.message;
  draft.getAll.config.isLoading = false;
}

export default getAllSuccessWithoutData;
