import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IImageData } from '../../index';

export interface IUploadImageRequestAction {
  data: {
    file: File;
  };
  functions: {
    close(uploadedUrl: string): void;
    error(err: any): void;
    success(message: string): void;
  };
}

function uploadRequest(
  draft: Draft<IImageData>,
  _: PayloadAction<IUploadImageRequestAction>,
) {
  draft.upload.config.isLoading = true;
}

export default uploadRequest;
