import { useNavigate } from 'react-router-dom';

import { FiChevronLeft } from 'react-icons/fi';

import { Container } from './styles';

const ComponentButtonBack = (): JSX.Element => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container onClick={handleGoBack}>
      <FiChevronLeft size={26} />
    </Container>
  );
};

export default ComponentButtonBack;
