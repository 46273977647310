import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

export interface IResetPasswordRequestAction {
  data: {
    newPassword: string;
    recoveryToken: string;
  };
  functions: {
    error: (err: any) => void;
    navigateTo: () => void;
    success: (message: string) => void;
  };
}

function resetPasswordRequest(
  draft: Draft<IAuthData>,
  _: PayloadAction<IResetPasswordRequestAction>,
) {
  draft.resetPassword.config.isLoading = true;
}

export default resetPasswordRequest;
