import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

export interface IGetAllToSelectManufacturerRequestAction {
  functions: {
    error(err: any): void;
  };
}

function getAllToSelectRequest(
  draft: Draft<IManufacturerData>,
  _: PayloadAction<IGetAllToSelectManufacturerRequestAction>,
) {
  draft.getAllToSelect.config.isLoading = true;
  draft.getAllToSelect.list = [];
}

export default getAllToSelectRequest;
