import { FiX } from 'react-icons/fi';

import {
  Bar,
  CloseButton,
  Container,
  LoadingBar,
  Message,
  Title,
} from './styles';

const ManufacturerImportWarning = (): JSX.Element => {
  return (
    <Container>
      <Title>Importando produtos</Title>
      <CloseButton>
        <FiX size={20} />
      </CloseButton>

      <Message>
        Aguarde enquanto a importação dos fabricantes está sendo realizada.
      </Message>

      <LoadingBar>
        <Bar />
      </LoadingBar>
    </Container>
  );
};

export default ManufacturerImportWarning;
