import { all, takeLatest } from 'redux-saga/effects';

import { userActions } from '../index';
import changePassword from './changePassword';
import changeStatus from './changeStatus';
import create from './create';
import edit from './edit';
import getAll from './getAll';

const userSagas = all([
  takeLatest(userActions.changePasswordRequest, changePassword),
  takeLatest(userActions.changeStatusRequest, changeStatus),
  takeLatest(userActions.createRequest, create),
  takeLatest(userActions.editRequest, edit),
  takeLatest(userActions.getAllRequest, getAll),
]);

export default userSagas;
