import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Parser } from 'json2csv';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostImportSpreadsheetProductResponse from 'services/httpClient/responses/product/IPostImportSpreadsheetProductResponse';

import { productActions } from '../index';
import { IImportSpreadsheetProductRequestAction } from '../reducers/importSpreadsheet/request';

function* importSpreadsheet(
  action: PayloadAction<IImportSpreadsheetProductRequestAction>,
) {
  try {
    const parser = new Parser();
    const csv = parser.parse(action.payload.data.products);
    const formData = new FormData();

    formData.append('products', csv);

    const response: AxiosResponse<IPostImportSpreadsheetProductResponse> =
      yield call(httpClient.post, `admins/products/bulk`, formData);

    yield put(
      productActions.importSpreadsheetSuccess({ id: response.data.id }),
    );

    action.payload.functions.success('A importação dos produtos foi iniciada.');
    action.payload.functions.goBack();
  } catch (err) {
    action.payload.functions.error(err);
    yield put(productActions.importSpreadsheetFailure());
  }
}

export default importSpreadsheet;
