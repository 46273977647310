import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IUserData } from '../../index';

export interface IChangePasswordUserRequestAction {
  data: {
    id: number;
    password: string;
  };
  functions: {
    closeForm: () => void;
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function changePasswordRequest(
  draft: Draft<IUserData>,
  _: PayloadAction<IChangePasswordUserRequestAction>,
) {
  draft.changePassword.config.isLoading = true;
}

export default changePasswordRequest;
