import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EImportSpreadsheetStatus from 'enums/importSpreadsheetStatus';
import helpers from 'helpers';
import { call, delay, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetCheckImportSpreadsheetResponse from 'services/httpClient/responses/product/IGetCheckImportSpreadsheetResponse';

import { productActions } from '../index';
import { ICheckImportSpreadsheetProductRequestAction } from '../reducers/checkImportSpreadsheet/request';

function* checkImportSpreadsheet(
  action: PayloadAction<ICheckImportSpreadsheetProductRequestAction>,
) {
  try {
    const response: AxiosResponse<IGetCheckImportSpreadsheetResponse> =
      yield call(httpClient.get, `admins/imports/${action.payload.data.id}`);
    if (response.data.status === EImportSpreadsheetStatus.Failure) {
      action.payload.functions.error('Falha ao importar produtos');
      yield put(
        productActions.setToggleImportSpreadsheetRemittanceInBackground({
          value: false,
        }),
      );
      return;
    }
    if (response.data.status !== EImportSpreadsheetStatus.Concluded) {
      yield delay(3000);
      yield put(
        productActions.checkImportSpreadsheetRequest({
          data: action.payload.data,
          functions: action.payload.functions,
        }),
      );
      return;
    }
    action.payload.functions.success('Produtos importados com sucesso');
    yield put(
      productActions.setToggleImportSpreadsheetRemittanceInBackground({
        value: false,
      }),
    );
    yield put(productActions.checkImportSpreadsheetSuccess());
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(
      productActions.setToggleImportSpreadsheetRemittanceInBackground({
        value: false,
      }),
    );
  }
}

export default checkImportSpreadsheet;
