import { useCallback, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { FiLock, FiMail } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';
import helpers from 'helpers';
import * as Yup from 'yup';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';
import ComponentInputSimple from 'components/input/Simple';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import authBackground from 'assets/background/auth-background.svg';
import authLogo from 'assets/logo/auth-logo.png';

import {
  Background,
  Container,
  Content,
  ForgotPasswordButton,
  FormikForm,
  Image,
  Logo,
  Subtitle,
} from './styles';

interface ISignInFormData {
  email: string;
  password: string;
}

const signInValidateSchema = Yup.object().shape({
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required('Informe o e-mail'),
  password: Yup.string().required('Informe a senha'),
});

const SignIn = (): JSX.Element => {
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();

  const token = useReduxSelector(authSelectors.token);
  const isLoading = useReduxSelector(authSelectors.signInIsLoading);

  const signInFormikRef = useRef<FormikProps<ISignInFormData>>(null);

  const handleSignIn = useCallback(
    (data: ISignInFormData) => {
      reduxDispatch(
        authActions.signInRequest({
          data: {
            email: data.email,
            password: data.password,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            navigateTo: () => {
              navigate(pages.product.list, { replace: true });
            },
          },
        }),
      );
    },
    [navigate, reduxDispatch],
  );

  useEffect(() => {
    if (token) {
      navigate(pages.product.list, { replace: true });
    }
  }, [navigate, token]);

  return (
    <Container>
      <Background>
        <Image alt="Imagem ilustrativa do sistema" src={authBackground} />
      </Background>
      <Content>
        <Logo alt="Logomarca do site" src={authLogo} />
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          innerRef={signInFormikRef}
          onSubmit={handleSignIn}
          validateOnChange={false}
          validationSchema={signInValidateSchema}
        >
          {({ errors, handleChange, values }) => (
            <FormikForm>
              <Subtitle>Efetuar login</Subtitle>

              <ComponentInputSimple
                errorMessage={errors.email}
                hasError={!!errors.email}
                height="2.75rem"
                icon={FiMail}
                onChange={handleChange('email')}
                placeholder="E-mail"
                type="email"
                value={values.email}
              />
              <ComponentInputPassword
                errorMessage={errors.password}
                hasError={!!errors.password}
                height="2.75rem"
                icon={FiLock}
                onChange={handleChange('password')}
                placeholder="Senha"
                value={values.password}
              />

              <ComponentButtonBase
                disabled={isLoading}
                height="2.5rem"
                isLoading={isLoading}
                type="submit"
              >
                Entrar
              </ComponentButtonBase>

              <ForgotPasswordButton to={pages.auth.forgotPassword}>
                Esqueci minha senha
              </ForgotPasswordButton>
            </FormikForm>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default SignIn;
