import { createSlice } from '@reduxjs/toolkit';
import EPdfType from 'enums/pdfType';
import EPlanTypes from 'enums/planTypes';
import EUserTypes from 'enums/userTypes';

import reducers from './reducers';

export interface IAuthDataAuthorization {
  token: string | null;
}

export interface IAuthDataProfile {
  company: {
    document: string | null;
    id: number | null;
    logo: string | null;
    name: string | null;
    pdfType: EPdfType | null;
  };
  email: string | null;
  id: number | null;
  name: string | null;
  plan: {
    id: number | null;
    type: EPlanTypes | null;
  };
  type: EUserTypes | null;
}

export interface IAuthData {
  authorization: IAuthDataAuthorization;
  changePassword: {
    config: {
      isLoading: boolean;
    };
  };
  config: {
    isLoading: boolean;
  };
  forgotPassword: {
    config: { isLoading: boolean };
  };
  profile: IAuthDataProfile;
  resetPassword: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IAuthData = {
  authorization: { token: null },
  changePassword: {
    config: {
      isLoading: false,
    },
  },
  config: {
    isLoading: false,
  },
  forgotPassword: {
    config: {
      isLoading: false,
    },
  },
  profile: {
    company: {
      document: null,
      id: null,
      logo: null,
      name: null,
      pdfType: null,
    },
    email: null,
    id: null,
    name: null,
    plan: {
      id: null,
      type: null,
    },
    type: null,
  },
  resetPassword: {
    config: {
      isLoading: false,
    },
  },
};

const authSlice = createSlice({
  name: '@auth',
  initialState,
  reducers,
});

export const authActions = authSlice.actions;
export const authReducers = authSlice.reducer;
