import Tables from 'styles/tables';

import { ProductItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <ProductItemsHeader>
      <Tables.ItemHeader center>Imagem</Tables.ItemHeader>
      <Tables.ItemHeader center>SKU</Tables.ItemHeader>
      <Tables.ItemHeader center>Código de barras</Tables.ItemHeader>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
      <Tables.ItemHeader>Fabricante</Tables.ItemHeader>
      <Tables.ItemHeader center>Ativo</Tables.ItemHeader>
      <Tables.ItemHeader center>Ações</Tables.ItemHeader>
    </ProductItemsHeader>
  );
};

export default ItemHeader;
