import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { IGetAllUserListResponse } from 'services/httpClient/responses/user/IGetAllUserResponse';

import { IUserData } from '../../index';

export interface IGetAllUserSuccessWithDataAction {
  list: IGetAllUserListResponse[];
  totalPages: number;
}

function getAllSuccessWithData(
  draft: Draft<IUserData>,
  action: PayloadAction<IGetAllUserSuccessWithDataAction>,
) {
  draft.getAll.config.errorMessage = '';
  draft.getAll.config.emptyMessage = '';
  draft.getAll.config.isLoading = false;
  draft.getAll.config.pagination.totalPages = action.payload.totalPages;
  draft.getAll.list = action.payload.list.map(user => ({
    ...user,
    phone: formatters.phone(user.phone),
  }));
}

export default getAllSuccessWithData;
