import { CSSProperties } from 'react';

import ComponentIsVisible from 'components/utils/IsVisible';

import Tables from 'styles/tables';

import { IManufacturerImportWithSelected } from '../../index';
import {
  ManufacturerImportCheckbox,
  ManufacturerImportCheckboxInput,
  ManufacturerImportCheckboxMark,
  ManufacturerImportItemsBody,
} from '../styles';

interface IItemBodyProps {
  handleToggleSelected: (index: number) => void;
  index: number;
  isInvalid?: boolean;
  manufacturers: IManufacturerImportWithSelected[];
  style: CSSProperties;
}

const ItemBody = ({
  handleToggleSelected,
  index,
  isInvalid,
  manufacturers,
  style,
}: IItemBodyProps): JSX.Element => {
  return (
    <ManufacturerImportItemsBody
      addColorRow={!!(index % 2 === 0)}
      isInvalid={isInvalid}
      style={style}
    >
      <ComponentIsVisible when={!isInvalid}>
        <Tables.ItemBody center>
          <ManufacturerImportCheckbox>
            <ManufacturerImportCheckboxInput
              checked={manufacturers[index].selected}
              className="checkboxInput"
              onChange={() => handleToggleSelected(index)}
              type="checkbox"
            />
            <ManufacturerImportCheckboxMark className="checkmark" />
          </ManufacturerImportCheckbox>
        </Tables.ItemBody>
      </ComponentIsVisible>
      <Tables.ItemBody>{manufacturers[index].nome}</Tables.ItemBody>
    </ManufacturerImportItemsBody>
  );
};

export default ItemBody;
