import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IImageData } from '../../index';

interface ISetClearUrlAction {
  success(): void;
}

function setClearUrl(
  draft: Draft<IImageData>,
  _: PayloadAction<ISetClearUrlAction>,
) {
  draft.upload.url = null;
}

export default setClearUrl;
