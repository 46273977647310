import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPutChangePasswordUserRequest from 'services/httpClient/request/user/IPutChangePasswordUserRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { userActions } from '../index';
import { IChangePasswordUserRequestAction } from '../reducers/changePassword/request';

function* changePassword(
  action: PayloadAction<IChangePasswordUserRequestAction>,
) {
  try {
    const body: IPutChangePasswordUserRequest = {
      password: action.payload.data.password.trim(),
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      `admins/users/${action.payload.data.id}/password`,
      body,
    );

    yield put(userActions.changePasswordSuccess());

    action.payload.functions.closeForm();
    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(userActions.changePasswordFailure());
  }
}

export default changePassword;
