import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { manufacturerActions } from '../index';
import { IChangeStatusManufacturerRequestAction } from '../reducers/changeStatus/request';

function* changeStatus(
  action: PayloadAction<IChangeStatusManufacturerRequestAction>,
) {
  try {
    let message = '';
    if (!action.payload.data.isActive) {
      const response: AxiosResponse<IHttpClientSuccess> = yield call(
        httpClient.post,
        `admins/manufacturers/${action.payload.data.id}/enable`,
      );
      message = response.data.message;
    } else {
      const response: AxiosResponse<IHttpClientSuccess> = yield call(
        httpClient.post,
        `admins/manufacturers/${action.payload.data.id}/disable`,
      );
      message = response.data.message;
    }

    yield put(
      manufacturerActions.changeStatusSuccess({ id: action.payload.data.id }),
    );

    action.payload.functions.success(message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(manufacturerActions.changeStatusFailure());
  }
}

export default changeStatus;
