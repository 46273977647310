import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IGetAllManufacturerListResponse } from 'services/httpClient/responses/manufacturer/IGetAllManufacturerResponse';

import { IManufacturerData } from '../../index';

export interface IGetAllToSelectManufacturerSuccessAction {
  list: IGetAllManufacturerListResponse[];
}

function getAllToSelectSuccess(
  draft: Draft<IManufacturerData>,
  action: PayloadAction<IGetAllToSelectManufacturerSuccessAction>,
) {
  draft.getAllToSelect.config.isLoading = false;
  draft.getAllToSelect.list = action.payload.list.map(manufacturer => ({
    label: manufacturer.name,
    value: manufacturer.id,
  }));
}

export default getAllToSelectSuccess;
