import Tables from 'styles/tables';

import { ManufacturerItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <ManufacturerItemsHeader>
      <Tables.ItemHeader center>Imagem</Tables.ItemHeader>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
      <Tables.ItemHeader center>Qtde. produtos</Tables.ItemHeader>
      <Tables.ItemHeader center>Ativo</Tables.ItemHeader>
      <Tables.ItemHeader center>Ações</Tables.ItemHeader>
    </ManufacturerItemsHeader>
  );
};

export default ItemHeader;
