import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column-end: 6;
  grid-column-start: 1;
  justify-content: center;
  padding: 3rem;
`;

export const EmptyImage = styled.img`
  margin-bottom: 1.5rem;
  max-width: 6.5rem;
  width: 100%;
`;

export const EmptyMessage = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
`;
