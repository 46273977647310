import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostSignInRequest from 'services/httpClient/request/auth/IPostSignInRequest';
import IGetSignInResponse from 'services/httpClient/responses/auth/IGetSignInResponse';
import IPostSignInResponse from 'services/httpClient/responses/auth/IPostSignInResponse';

import { authActions } from '..';

import { ISignInRequestAction } from '../reducers/signIn/request';

function* signIn(action: PayloadAction<ISignInRequestAction>) {
  try {
    const body: IPostSignInRequest = {
      email: action.payload.data.email,
      password: action.payload.data.password,
    };

    const {
      data: { token },
    }: AxiosResponse<IPostSignInResponse> = yield call(
      httpClient.post,
      'sessions/admins',
      body,
    );

    httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;

    yield put(
      authActions.signInSuccess({
        token,
      }),
    );

    const { data }: AxiosResponse<IGetSignInResponse> = yield call(
      httpClient.get,
      `sessions/admins`,
    );
    yield put(authActions.setProfile(data));

    action.payload.functions.navigateTo();
  } catch (err: any) {
    action.payload.functions.error(err);
    yield put(authActions.signInFailure());
  }
}

export default signIn;
