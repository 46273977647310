import editFailure from './edit/failure';
import editRequest from './edit/request';
import editSuccess from './edit/success';

const reducers = {
  editRequest,
  editSuccess,
  editFailure,
};

export default reducers;
