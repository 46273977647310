function amountMoney(
  amount: number,
  decimal: number,
  prefix?: boolean,
): string {
  const amountString = String(amount).replace('.', ',');

  let amountFormatted = amountString;

  const period = amountString.indexOf(',');
  if (period > -1) {
    amountFormatted =
      amountString.substring(0, period).replace(/\s|\D/g, '') +
      amountString.substring(period + 1).replace(/\s|\D/g, '');
  }

  let len = amountFormatted.length;

  while (len <= decimal) {
    amountFormatted = `0${amountFormatted}`;
    len = amountFormatted.length;
  }

  amountFormatted = `${amountFormatted.substring(
    0,
    len - decimal,
  )},${amountFormatted.substring(len - decimal, len)}`;

  while (amountFormatted.length > 4 && amountFormatted[0] === '0') {
    amountFormatted = amountFormatted.substring(1);
  }

  if (amountFormatted[0] === ',') {
    amountFormatted = `0${amountFormatted}`;
  }

  return prefix ? `${prefix} ${amountFormatted}` : amountFormatted;
}

function money(value: string, decimal?: number): string {
  const valueClean = value.replace(/[^0-9]/g, '');

  const n = valueClean[valueClean.length - 1];

  if (!value) {
    return '';
  }

  if (Number.isNaN(n)) {
    const moneyTypingFormatted = valueClean.substring(0, valueClean.length - 1);

    return moneyTypingFormatted;
  }

  const moneyTypingFormatted = amountMoney(
    Number(valueClean),
    decimal as number,
  );

  return moneyTypingFormatted;
}

export default money;
