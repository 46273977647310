import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FormikProps } from 'formik';
import helpers from 'helpers';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import imageSelectors from 'store/slices/image/selectors';
import { manufacturerActions } from 'store/slices/manufacturer';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';

import ManufacturerForm, { IManufacturerFormData } from '../Form';

export interface IManufacturerCreateRefProps {
  close(): void;
  open(): void;
}

interface IManufacturerCreateProps {
  reloadManufacturers(): void;
}

const ManufacturerCreate: React.ForwardRefRenderFunction<
  IManufacturerCreateRefProps,
  IManufacturerCreateProps
> = ({ reloadManufacturers }, ref) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(manufacturerSelectors.createIsLoading);
  const uploadedImageUrl = useReduxSelector(imageSelectors.uploadUrl);

  const [hideModal, setHideModal] = useState<boolean>(false);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const manufacturerFormRef = useRef<FormikProps<IManufacturerFormData>>(null);

  const openModal = useCallback(() => {
    setHideModal(false);
    componentModalBaseRef.current?.open();
  }, []);
  const closeModal = useCallback(() => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleCreateManufacturer = useCallback(
    async (data: IManufacturerFormData) => {
      manufacturerFormRef.current?.setErrors({});
      reduxDispatch(
        manufacturerActions.createRequest({
          data: {
            name: data.name,
            logo: uploadedImageUrl || undefined,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            closeForm: () => {
              reloadManufacturers();
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, reduxDispatch, reloadManufacturers, toast, uploadedImageUrl],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''}>
        <Title>Novo fabricante</Title>
        <ManufacturerForm
          handleClose={closeModal}
          isLoading={isLoading}
          onSubmit={handleCreateManufacturer}
          ref={manufacturerFormRef}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ManufacturerCreate);
