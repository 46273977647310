const fileOptions = [
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

function importFileType(importFileType: string): boolean {
  if (!importFileType) {
    return false;
  }

  const fileVerified = fileOptions.includes(importFileType, 0);

  return fileVerified;
}

export default importFileType;
