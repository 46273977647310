import styled from 'styled-components';

import { Form } from 'formik';

import colors from './colors';

interface ISubtitleProps {
  color?: string;
  marginTop?: string;
}

const Subtitle = styled.strong<ISubtitleProps>`
  color: ${props => (props.color ? props.color : colors.cyan900)};
  display: flex;
  font-size: 0.93rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: ${props => (props.marginTop ? props.marginTop : '1.5')}rem;
`;

interface IContentProps {
  marginTop?: string;
}

const Content = styled(Form)<IContentProps>`
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')}rem;
`;

interface IGridProps {
  alignItems?: string;
  gridTemplateColumns?: string;
  gridTemplateColumnsSmall?: string;
  marginBottom?: string;
}
const Grid = styled.div<IGridProps>`
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-end')};
  column-gap: 0.25rem;
  display: grid;
  grid-template-columns: ${props =>
    props.gridTemplateColumnsSmall
      ? props.gridTemplateColumnsSmall
      : props.gridTemplateColumns};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '1')}rem;
  row-gap: 0.5rem;
  width: 100%;

  @media screen and (min-width: 1240px) {
    grid-template-columns: ${props =>
      props.gridTemplateColumns ? props.gridTemplateColumns : '1fr'};
  }
`;

interface IActions {
  gridTemplateColumns?: string;
  justifyContent?: string;
}

const Actions = styled.div<IActions>`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: ${props =>
    props.gridTemplateColumns ? props.gridTemplateColumns : '1fr'};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'center'};
  margin-top: 2rem;
  width: 100%;
`;

export default {
  Subtitle,
  Content,
  Grid,
  Actions,
};
