import { Draft } from '@reduxjs/toolkit';

import { IUserData } from '../../index';

function getAllFailure(draft: Draft<IUserData>) {
  draft.getAll.config.errorMessage = 'Não foi possível carregar os usuários';
  draft.getAll.config.isLoading = false;
}

export default getAllFailure;
