import { FiCheckCircle, FiEdit, FiSlash, FiXCircle } from 'react-icons/fi';

import IManufacturer from 'models/Manufacturer';

import ComponentIsVisible from 'components/utils/IsVisible';

import withoutPicture from 'assets/utils/without-picture.png';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { ManufacturerItemsBody } from '../styles';

interface IItemBodyProps {
  manufacturer: IManufacturer;
  openChangeStatusAlert: (data: IManufacturer) => void;
  openEdit: (data: IManufacturer) => void;
  position: number;
}

const ItemBody = ({
  manufacturer,
  openChangeStatusAlert,
  openEdit,
  position,
}: IItemBodyProps): JSX.Element => {
  return (
    <ManufacturerItemsBody addColorRow={!!(position % 2)}>
      <Tables.ItemBody center>
        <Tables.ItemPicture
          alt={
            manufacturer.logo ? 'Imagem do fabricante' : 'Fabricante sem imagem'
          }
          src={manufacturer.logo || withoutPicture}
        />
      </Tables.ItemBody>
      <Tables.ItemBody>{manufacturer.name}</Tables.ItemBody>
      <Tables.ItemBody center>{manufacturer.productsAmount}</Tables.ItemBody>
      <Tables.ItemBody center>
        <ComponentIsVisible when={manufacturer.isActive}>
          <FiCheckCircle color={colors.orange900} size={18} title="Ativo" />
        </ComponentIsVisible>
        <ComponentIsVisible when={!manufacturer.isActive}>
          <FiXCircle color={colors.red500} size={18} title="Desativado" />
        </ComponentIsVisible>
      </Tables.ItemBody>
      <Tables.ItemBody center>
        <Tables.ItemActions>
          <Tables.ItemAction
            onClick={() => openEdit(manufacturer)}
            title="Editar fabricante"
          >
            <FiEdit color={colors.cyan900} size={18} />
          </Tables.ItemAction>
          <Tables.ItemAction
            onClick={() => openChangeStatusAlert(manufacturer)}
          >
            <ComponentIsVisible when={manufacturer.isActive}>
              <FiSlash
                color={colors.red500}
                size={18}
                title="Desativar fabricante"
              />
            </ComponentIsVisible>
            <ComponentIsVisible when={!manufacturer.isActive}>
              <FiCheckCircle
                color={colors.green900}
                size={18}
                title="Ativar fabricante"
              />
            </ComponentIsVisible>
          </Tables.ItemAction>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </ManufacturerItemsBody>
  );
};

export default ItemBody;
