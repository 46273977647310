import { useCallback, useEffect } from 'react';

import { useReduxDispatch } from 'hooks/useReduxDispatch';

import { authActions } from 'store/slices/auth';

import RedirectManager from './RedirectManager';

export function callRedirect(): void {
  const ref = RedirectManager.getDefault();

  if (ref) {
    ref.redirect();
  }
}

const Redirect: React.FC = () => {
  const reduxDispatch = useReduxDispatch();
  const redirect = useCallback(() => {
    reduxDispatch(authActions.logoutRequest());
  }, [reduxDispatch]);

  useEffect(() => {
    RedirectManager.register({ redirect });
  }, [redirect]);

  return <></>;
};

export default Redirect;
