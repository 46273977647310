interface IRemoveMaskOptions {
  defaultValue?: string;
}

function removeMask(value?: string, options?: IRemoveMaskOptions): string {
  if (!value) {
    return options?.defaultValue || '';
  }

  const valueClean = value.replace(/[^0-9]/g, '');

  return valueClean;
}

export default removeMask;
