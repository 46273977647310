import styled from 'styled-components';

export const Message = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`;

export const Bold = styled.b`
  font-weight: 600;
`;

export const Actions = styled.div`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 8rem 1fr;
  margin-top: 1.5rem;
`;
