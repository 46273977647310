import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

export interface IEditManufacturerRequestAction {
  data: {
    id: number;
    logo: string | null;
    name: string;
  };
  functions: {
    closeForm: () => void;
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function editRequest(
  draft: Draft<IManufacturerData>,
  _: PayloadAction<IEditManufacturerRequestAction>,
) {
  draft.edit.config.isLoading = true;
}

export default editRequest;
