import { authReducers, IAuthData } from './slices/auth';
import { IImageData, imageReducers } from './slices/image';
import { IManufacturerData, manufacturerReducers } from './slices/manufacturer';
import { IProductData, productReducers } from './slices/product';
import { ISettingsData, settingsReducers } from './slices/settings';
import { IUserData, userReducers } from './slices/user';

export interface IApplicationState {
  auth: IAuthData;
  image: IImageData;
  manufacturer: IManufacturerData;
  product: IProductData;
  settings: ISettingsData;
  user: IUserData;
}

const rootReducers = {
  auth: authReducers,
  image: imageReducers,
  manufacturer: manufacturerReducers,
  product: productReducers,
  settings: settingsReducers,
  user: userReducers,
};

export default rootReducers;
