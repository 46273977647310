import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EUserTypes from 'enums/userTypes';
import formatters from 'formatters';

import { IUserData } from '../../index';

interface IEditSuccessAction {
  email: string;
  id: number;
  name: string;
  phone: string;
  type: EUserTypes;
}

function editSuccess(
  draft: Draft<IUserData>,
  action: PayloadAction<IEditSuccessAction>,
) {
  draft.edit.config.isLoading = false;
  const userIndex = draft.getAll.list.findIndex(
    user => user.id === action.payload.id,
  );
  const userToEdit = draft.getAll.list[userIndex];
  draft.getAll.list[userIndex] = {
    isActive: userToEdit.isActive,
    name: action.payload.name,
    id: userToEdit.id,
    email: action.payload.email,
    phone: formatters.phone(action.payload.phone),
    type: action.payload.type,
  };
}

export default editSuccess;
