import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { FiMail } from 'react-icons/fi';

import { Formik } from 'formik';
import helpers from 'helpers';
import * as Yup from 'yup';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputSimple from 'components/input/Simple';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import authBackground from 'assets/background/auth-background.svg';
import authLogo from 'assets/logo/auth-logo.png';

import {
  BackButton,
  Container,
  Content,
  FormContent,
  Image,
  Logo,
  Subtitle,
  Title,
} from './styles';

interface IForgotPasswordFormData {
  email: string;
}

const initialValues: IForgotPasswordFormData = {
  email: '',
};

const forgotPasswordValidateSchema = Yup.object().shape({
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required('Informe o e-mail'),
});

const ForgotPassword = (): JSX.Element => {
  const toast = useToast();
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(authSelectors.forgotPasswordIsLoading);

  const handleForgotPassword = useCallback(
    (data: IForgotPasswordFormData) => {
      reduxDispatch(
        authActions.forgotPasswordRequest({
          data: {
            email: data.email,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            navigateTo: () => {
              navigate(pages.auth.signIn, { replace: true });
            },
            success: (message: string) => {
              toast.show({
                title: message,
                type: 'success',
              });
            },
          },
        }),
      );
    },
    [navigate, reduxDispatch, toast],
  );

  return (
    <Container>
      <Content>
        <Image alt="Imagem ilustrativa do sistema" src={authBackground} />
      </Content>

      <Content formContent>
        <Logo alt="Logomarca do site" src={authLogo} />

        <Title>Esqueceu sua senha?</Title>
        <Subtitle>
          Preencha o campo abaixo com o e-mail cadastrado e na sequência fique
          de olho em sua caixa de entrada ou spam.
        </Subtitle>
        <Formik
          initialValues={initialValues}
          onSubmit={handleForgotPassword}
          validateOnChange={false}
          validationSchema={forgotPasswordValidateSchema}
        >
          {({ errors, handleChange, values }) => (
            <FormContent>
              <ComponentInputSimple
                errorMessage={errors.email}
                hasError={!!errors.email}
                height="2.75rem"
                icon={FiMail}
                onChange={handleChange('email')}
                placeholder="E-mail"
                type="email"
                value={values.email}
              />
              <ComponentButtonBase
                disabled={isLoading}
                height="2.5rem"
                isLoading={isLoading}
                type="submit"
              >
                Recuperar senha
              </ComponentButtonBase>
            </FormContent>
          )}
        </Formik>

        <BackButton to={pages.auth.signIn}>Voltar ao login</BackButton>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
