import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

interface IImportSpreadsheetProductSuccessAction {
  id: number;
}

function importSpreadsheetSuccess(
  draft: Draft<IProductData>,
  action: PayloadAction<IImportSpreadsheetProductSuccessAction>,
) {
  draft.importSpreadsheet.config.isLoading = false;
  draft.importSpreadsheet.generatingInBackground = true;
  draft.importSpreadsheet.id = action.payload.id;
}

export default importSpreadsheetSuccess;
