import Tables from 'styles/tables';

import { UserItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <UserItemsHeader>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
      <Tables.ItemHeader>E-mail</Tables.ItemHeader>
      <Tables.ItemHeader>Telefone</Tables.ItemHeader>
      <Tables.ItemHeader center>Tipo</Tables.ItemHeader>
      <Tables.ItemHeader center>Ativo</Tables.ItemHeader>
      <Tables.ItemHeader center>Ações</Tables.ItemHeader>
    </UserItemsHeader>
  );
};

export default ItemHeader;
