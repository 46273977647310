import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EPdfType from 'enums/pdfType';

import { ISettingsData } from '../../index';

export interface IEditSettingsRequestAction {
  data: {
    logo: string;
    name: string;
    pdfType: EPdfType;
  };
  functions: {
    error(err: any): void;
    success(message: string): void;
  };
}

function editRequest(
  draft: Draft<ISettingsData>,
  _: PayloadAction<IEditSettingsRequestAction>,
) {
  draft.edit.config.isLoading = true;
}

export default editRequest;
