import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

export interface IChangeStatusManufacturerRequestAction {
  data: {
    id: number;
    isActive: boolean;
  };
  functions: {
    error(err: any): void;
    success(message: string): void;
  };
}

function changeStatusRequest(
  draft: Draft<IManufacturerData>,
  _: PayloadAction<IChangeStatusManufacturerRequestAction>,
) {
  draft.changeStatus.config.isLoading = true;
}

export default changeStatusRequest;
