import ComponentIsVisible from 'components/utils/IsVisible';

import Tables from 'styles/tables';

import { ProductImportItemsHeader } from '../styles';

interface IItemHeaderProps {
  isInvalid?: boolean;
}

const ItemHeader = ({ isInvalid }: IItemHeaderProps): JSX.Element => {
  return (
    <ProductImportItemsHeader isInvalid={isInvalid}>
      <ComponentIsVisible when={!isInvalid}>
        <Tables.ItemHeader center />
      </ComponentIsVisible>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
      <Tables.ItemHeader>Código de barras</Tables.ItemHeader>
      <Tables.ItemHeader>Fabricante</Tables.ItemHeader>
      <Tables.ItemHeader>SKU</Tables.ItemHeader>
      <Tables.ItemHeader>Apresentação</Tables.ItemHeader>
      <Tables.ItemHeader>Outras informações</Tables.ItemHeader>
    </ProductImportItemsHeader>
  );
};

export default ItemHeader;
