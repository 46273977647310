import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Parser } from 'json2csv';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { manufacturerActions } from '../index';
import { IImportSpreadsheetManufacturerRequestAction } from '../reducers/importSpreadsheet/request';

function* importSpreadsheet(
  action: PayloadAction<IImportSpreadsheetManufacturerRequestAction>,
) {
  try {
    const parser = new Parser();
    const csv = parser.parse(action.payload.data.manufacturers);
    const formData = new FormData();

    formData.append('manufacturers', csv);

    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.post,
      `admins/manufacturers/bulk`,
      formData,
    );

    yield put(manufacturerActions.importSpreadsheetSuccess());

    action.payload.functions.goBack();
    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(manufacturerActions.importSpreadsheetFailure());
  }
}

export default importSpreadsheet;
