import { createSlice } from '@reduxjs/toolkit';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import IPagination from 'interfaces/IPagination';
import IManufacturer from 'models/Manufacturer';

import reducers from './reducers';

export interface IManufacturerData {
  changeStatus: {
    config: {
      isLoading: boolean;
    };
  };
  create: {
    config: {
      isLoading: boolean;
    };
  };
  edit: {
    config: {
      isLoading: boolean;
    };
  };
  getAll: {
    config: {
      emptyMessage: string;
      errorMessage: string;
      isLoading: boolean;
      pagination: IPagination;
    };
    list: IManufacturer[];
  };
  getAllToSelect: {
    config: {
      isLoading: boolean;
    };
    list: IComponentSelectProps[];
  };
  importSpreadsheet: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IManufacturerData = {
  changeStatus: {
    config: {
      isLoading: false,
    },
  },
  create: {
    config: {
      isLoading: false,
    },
  },
  edit: {
    config: {
      isLoading: false,
    },
  },
  getAll: {
    config: {
      emptyMessage: 'Nenhum fabricante encontrado',
      errorMessage: '',
      isLoading: false,
      pagination: {
        page: 1,
        totalPages: 1,
      },
    },
    list: [],
  },
  getAllToSelect: {
    config: {
      isLoading: false,
    },
    list: [],
  },
  importSpreadsheet: {
    config: {
      isLoading: false,
    },
  },
};

const manufacturerSlice = createSlice({
  name: '@manufacturer',
  initialState,
  reducers,
});

export const manufacturerActions = manufacturerSlice.actions;
export const manufacturerReducers = manufacturerSlice.reducer;
