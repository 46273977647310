import changePasswordIsLoading from './changePasswordIsLoading';
import forgotPasswordIsLoading from './forgotPasswordIsLoading';
import profile from './profile';
import resetPasswordIsLoading from './resetPasswordIsLoading';
import signInIsLoading from './signInIsLoading';
import token from './token';

const authSelectors = {
  changePasswordIsLoading,
  forgotPasswordIsLoading,
  profile,
  resetPasswordIsLoading,
  signInIsLoading,
  token,
};

export default authSelectors;
