import { CSSProperties } from 'react';

import ComponentIsVisible from 'components/utils/IsVisible';

import Tables from 'styles/tables';

import { IProductImportWithSelected } from '../../index';
import {
  ProductImportCheckbox,
  ProductImportCheckboxInput,
  ProductImportCheckboxMark,
  ProductImportItemsBody,
} from '../styles';

interface IItemBodyProps {
  handleToggleSelected: (index: number) => void;
  index: number;
  isInvalid?: boolean;
  products: IProductImportWithSelected[];
  style: CSSProperties;
}

const ItemBody = ({
  handleToggleSelected,
  index,
  isInvalid,
  products,
  style,
}: IItemBodyProps): JSX.Element => {
  return (
    <ProductImportItemsBody
      addColorRow={!!(index % 2 === 0)}
      isInvalid={isInvalid}
      style={style}
    >
      <ComponentIsVisible when={!isInvalid}>
        <Tables.ItemBody center>
          <ProductImportCheckbox>
            <ProductImportCheckboxInput
              checked={products[index].selected}
              className="checkboxInput"
              onChange={() => handleToggleSelected(index)}
              type="checkbox"
            />
            <ProductImportCheckboxMark className="checkmark" />
          </ProductImportCheckbox>
        </Tables.ItemBody>
      </ComponentIsVisible>
      <Tables.ItemBody>{products[index].name}</Tables.ItemBody>
      <Tables.ItemBody>
        {products[index].ean || 'Não informado'}
      </Tables.ItemBody>
      <Tables.ItemBody>
        {products[index].manufacturer || 'Não informado'}
      </Tables.ItemBody>
      <Tables.ItemBody>
        {products[index].sku || 'Não informado'}
      </Tables.ItemBody>
      <Tables.ItemBody>
        {products[index].description || 'Não informado'}
      </Tables.ItemBody>
      <Tables.ItemBody>
        {products[index].otherInfo || 'Não informado'}
      </Tables.ItemBody>
    </ProductImportItemsBody>
  );
};

export default ItemBody;
