import ComponentInputBase, {
  IComponentInputBaseProps,
} from 'components/input/Base';

type IComponentInputSimpleProps = IComponentInputBaseProps;

const ComponentInputSimple: React.FC<IComponentInputSimpleProps> = props => {
  return <ComponentInputBase {...props} />;
};

export default ComponentInputSimple;
