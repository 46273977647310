import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostImageResponse from 'services/httpClient/responses/image/IPostImageResponse';

import { imageActions } from '../index';
import { IUploadImageRequestAction } from '../reducers/upload/request';

function* upload(action: PayloadAction<IUploadImageRequestAction>) {
  try {
    const formData = new FormData();

    formData.append('file', action.payload.data.file);

    const response: AxiosResponse<IPostImageResponse> = yield call(
      httpClient.post,
      'images',
      formData,
    );

    yield put(
      imageActions.uploadSuccess({
        url: response.data.url,
      }),
    );
    action.payload.functions.success(response.data.message);
    action.payload.functions.close(response.data.url);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(imageActions.uploadFailure());
  }
}

export default upload;
