import setClearUrl from './set/clearUrl';
import uploadFailure from './upload/failure';
import uploadRequest from './upload/request';
import uploadSuccess from './upload/success';

const reducers = {
  setClearUrl,
  uploadRequest,
  uploadSuccess,
  uploadFailure,
};

export default reducers;
