import changeStatusIsLoading from './changeStatusIsLoading';
import createIsLoading from './createIsLoading';
import editIsLoading from './editIsLoading';
import getAllEmptyMessage from './getAllEmptyMessage';
import getAllErrorMessage from './getAllErrorMessage';
import getAllIsLoading from './getAllIsLoading';
import getAllList from './getAllList';
import getAllPagination from './getAllPagination';
import getAllToSelectIsLoading from './getAllToSelectIsLoading';
import getAllToSelectList from './getAllToSelectList';
import importSpreadsheetIsLoading from './importSpreadsheetIsLoading';

const manufacturerSelectors = {
  changeStatusIsLoading,
  createIsLoading,
  editIsLoading,
  getAllEmptyMessage,
  getAllList,
  getAllIsLoading,
  getAllErrorMessage,
  getAllPagination,
  getAllToSelectList,
  getAllToSelectIsLoading,
  importSpreadsheetIsLoading,
};

export default manufacturerSelectors;
