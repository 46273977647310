import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostCreateManufacturerRequest from 'services/httpClient/request/manufacturer/IPostCreateManufacturerRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { manufacturerActions } from '../index';
import { ICreateManufacturerRequestAction } from '../reducers/create/request';

function* create(action: PayloadAction<ICreateManufacturerRequestAction>) {
  try {
    const body: IPostCreateManufacturerRequest = {
      name: action.payload.data.name.trim(),
      logo: action.payload.data.logo || undefined,
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.post,
      'admins/manufacturers',
      body,
    );

    yield put(manufacturerActions.createSuccess());

    action.payload.functions.closeForm();
    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(manufacturerActions.createFailure());
  }
}

export default create;
