import { InputHTMLAttributes } from 'react';

import { Container, Input, Mark, Radio } from './styles';

interface IComponentInputRadioProps
  extends InputHTMLAttributes<HTMLInputElement> {
  fieldLabel?: string;
}

const ComponentInputRadio: React.FC<IComponentInputRadioProps> = ({
  fieldLabel,
  ...props
}) => {
  return (
    <Container>
      <Radio>
        <Input type="radio" {...props} />
        <Mark className="circlemark" />
        {fieldLabel}
      </Radio>
    </Container>
  );
};

export default ComponentInputRadio;
