import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

export interface ICheckImportSpreadsheetProductRequestAction {
  data: {
    id: number;
  };
  functions: {
    error(err: string): void;
    success(url: string): void;
  };
}

function checkImportSpreadsheetRequest(
  _: Draft<IProductData>,
  __: PayloadAction<ICheckImportSpreadsheetProductRequestAction>,
) {
  //
}

export default checkImportSpreadsheetRequest;
