import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

export interface IRemoveProductRequestAction {
  data: {
    id: number;
  };
  functions: {
    error(err: any): void;
    success(message: string): void;
  };
}

function removeRequest(
  draft: Draft<IProductData>,
  _: PayloadAction<IRemoveProductRequestAction>,
) {
  draft.remove.config.isLoading = true;
}

export default removeRequest;
