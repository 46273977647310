import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

export interface IGetAllManufacturerRequestAction {
  data: {
    name?: string;
    page?: number;
  };
  functions: {
    error: (err: any) => void;
  };
}

function getAllRequest(
  draft: Draft<IManufacturerData>,
  action: PayloadAction<IGetAllManufacturerRequestAction>,
) {
  draft.getAll.config.errorMessage = '';
  draft.getAll.config.emptyMessage = '';
  draft.getAll.config.isLoading = true;
  draft.getAll.config.pagination.totalPages = 1;
  draft.getAll.config.pagination.page = action.payload.data.page;
  draft.getAll.list = [];
}

export default getAllRequest;
