import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import ComponentButtonBase from 'components/button/Base';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import colors from 'styles/colors';

import { Actions, Bold, Message } from './styles';

export interface IAlertData {
  id: number;
  isActive?: boolean;
  name: string;
  remove?: boolean;
}

export interface IComponentAlertRefProps {
  close(): void;
  open(data: IAlertData): void;
}

interface IComponentAlertProps {
  isLoading: boolean;
  onConfirm(data: IAlertData): void;
}

const ComponentAlert: React.ForwardRefRenderFunction<
  IComponentAlertRefProps,
  IComponentAlertProps
> = ({ isLoading, onConfirm }, ref) => {
  const [hideModal, setHideModal] = useState<boolean>(false);
  const [item, setItem] = useState<IAlertData>({} as IAlertData);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const openModal = useCallback((data: IAlertData) => {
    setItem(data);
    setHideModal(false);
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleConfirm = useCallback(() => {
    onConfirm(item);
  }, [item, onConfirm]);

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  const textOperation = useMemo(() => {
    if (item.remove) {
      return 'remover';
    }
    return item.isActive ? 'desativar' : 'ativar';
  }, [item.isActive, item.remove]);

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''}>
        <Title>Confirmação pendente</Title>

        <Message>
          Você está prestes a <Bold>{textOperation}</Bold> o item
          <Bold> {item.name}</Bold>, tem certeza que deseja continuar?
        </Message>

        <Actions>
          <ComponentButtonBase
            backgroundColor={colors.red500}
            disabled={isLoading}
            onClick={closeModal}
            type="button"
          >
            Cancelar
          </ComponentButtonBase>
          <ComponentButtonBase
            backgroundColor={colors.orange700}
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleConfirm}
            type="button"
          >
            Sim, tenho certeza
          </ComponentButtonBase>
        </Actions>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ComponentAlert);
