import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import { Form } from 'formik';
import { shade } from 'polished';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  background: ${colors.background};
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  width: 100%;
  @media (min-width: 850px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface IContentProps {
  formContent?: boolean;
}

export const Content = styled.div<IContentProps>`
  background: ${colors.gray900};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 35vh;
  padding: 1rem;
  @media (min-width: 850px) {
    padding: 1rem 3.5rem;
    min-height: 100vh;
  }

  ${props =>
    props.formContent &&
    css`
      align-items: flex-start;
      animation: ${animations.rightIn} 1s ease;
      background: ${colors.background};
      justify-content: space-around;
      margin: 0 auto;
      max-width: 18.75rem;
      width: 100%;
      @media (min-width: 850px) {
        max-width: 20rem;
        padding: 0;
        min-height: auto;
      }
    `}
`;

export const Image = styled.img`
  display: flex;
  margin: 0 auto;
  max-width: 30rem;
  width: 100%;
`;

export const Logo = styled.img`
  margin: 2.5rem auto;
  max-width: 11.25rem;
`;

export const Title = styled.h4`
  color: ${colors.gray600};
  font-size: 0.925rem;
  font-weight: 600;
  max-width: 25rem;
  @media screen and (min-width: 850px) {
    font-size: 0.975rem;
  }
`;

export const Subtitle = styled.p`
  color: ${colors.gray600};
  font-size: 0.75rem;
  margin-top: 0.375rem;
  max-width: 28rem;
  @media (min-width: 850px) {
    font-size: 0.8rem;
  }
`;

export const FormContent = styled(Form)`
  margin-top: 0.25rem;
  width: 100%;
`;

export const BackButton = styled(Link)`
  background: ${colors.transparent};
  border: 0;
  color: ${colors.gray600};
  font-size: 0.925rem;
  margin: 2rem auto 0;
  text-decoration: none;
  transition: all 0.3s ease;
  @media (min-width: 850px) {
    &:hover {
      color: ${shade(0.2, colors.gray600)};
    }
  }
`;
