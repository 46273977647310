import changePasswordFailure from './changePassword/failure';
import changePasswordRequest from './changePassword/request';
import changePasswordSuccess from './changePassword/success';
import changeStatusFailure from './changeStatus/failure';
import changeStatusRequest from './changeStatus/request';
import changeStatusSuccess from './changeStatus/success';
import createFailure from './create/failure';
import createRequest from './create/request';
import createSuccess from './create/success';
import editFailure from './edit/failure';
import editRequest from './edit/request';
import editSuccess from './edit/success';
import getAllFailure from './getAll/failure';
import getAllRequest from './getAll/request';
import getAllSuccessWithData from './getAll/successWithData';
import getAllSuccessWithoutData from './getAll/successWithoutData';

const reducers = {
  changePasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  changeStatusFailure,
  changeStatusRequest,
  changeStatusSuccess,
  createFailure,
  createRequest,
  createSuccess,
  editFailure,
  editRequest,
  editSuccess,
  getAllFailure,
  getAllRequest,
  getAllSuccessWithData,
  getAllSuccessWithoutData,
};

export default reducers;
