import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import formatters from 'formatters';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostCreateUserRequest from 'services/httpClient/request/user/IPostCreateUserRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { userActions } from '../index';
import { ICreateUserRequestAction } from '../reducers/create/request';

function* create(action: PayloadAction<ICreateUserRequestAction>) {
  try {
    const body: IPostCreateUserRequest = {
      name: action.payload.data.name.trim(),
      email: action.payload.data.email.trim(),
      phone: formatters.removeMask(action.payload.data.phone).trim(),
      type: action.payload.data.type,
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.post,
      'admins/users',
      body,
    );

    yield put(userActions.createSuccess());

    action.payload.functions.closeForm();
    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(userActions.createFailure());
  }
}

export default create;
