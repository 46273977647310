import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPutEditSettingsRequest from 'services/httpClient/request/settings/IPutEditSettingsRequest';
import IGetSignInResponse from 'services/httpClient/responses/auth/IGetSignInResponse';
import IPostImageResponse from 'services/httpClient/responses/image/IPostImageResponse';

import { authActions } from 'store/slices/auth';

import { settingsActions } from '../index';
import { IEditSettingsRequestAction } from '../reducers/edit/request';

function* edit(action: PayloadAction<IEditSettingsRequestAction>) {
  try {
    const body: IPutEditSettingsRequest = {
      name: action.payload.data.name.trim(),
      logo: action.payload.data.logo,
      pdfType: action.payload.data.pdfType,
    };

    const response: AxiosResponse<IPostImageResponse> = yield call(
      httpClient.put,
      'admins/companies',
      body,
    );

    yield put(settingsActions.editSuccess());

    const { data }: AxiosResponse<IGetSignInResponse> = yield call(
      httpClient.get,
      `sessions/admins`,
    );
    yield put(authActions.setProfile(data));

    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(settingsActions.editFailure());
  }
}

export default edit;
