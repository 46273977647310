import cnpj from './cnpj';
import cpf from './cpf';

interface ICpfOrCnpjOptions {
  defaultValue?: string;
}

function cpfOrCnpj(value?: string | null, options?: ICpfOrCnpjOptions): string {
  if (!value) {
    return options?.defaultValue || '';
  }

  const cpfOrCnpjClean = value.replace(/[^0-9]/g, '');

  if (cpfOrCnpjClean.length > 11) {
    return cnpj(value);
  }

  return cpf(value);
}

export default cpfOrCnpj;
