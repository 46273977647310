import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EUserTypes from 'enums/userTypes';

import { IUserData } from '../../index';

export interface ICreateUserRequestAction {
  data: {
    email: string;
    name: string;
    phone: string;
    type: EUserTypes;
  };
  functions: {
    closeForm: () => void;
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function createRequest(
  draft: Draft<IUserData>,
  _: PayloadAction<ICreateUserRequestAction>,
) {
  draft.create.config.isLoading = true;
}

export default createRequest;
