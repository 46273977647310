import styled from 'styled-components';

import colors from 'styles/colors';

export const Header = styled.header`
  margin-bottom: 1rem;
  width: 100%;
`;

export const Content = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const UserName = styled.p`
  margin-right: 2rem;
  text-align: right;
`;

export const Small = styled.small`
  display: flex;
`;

export const LogoutButton = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  display: flex;
  font-size: 0;
  justify-content: center;

  &:hover {
    opacity: 0.6;
  }
`;
