import { useRef } from 'react';

import ComponentInputBase, {
  IComponentInputBaseProps,
  IComponentInputBaseRefProps,
} from '../Base';

type IComponentInputDocumentProps = IComponentInputBaseProps;

const ComponentInputDocument: React.FC<
  IComponentInputDocumentProps
> = props => {
  const componentInputBaseRef = useRef<IComponentInputBaseRefProps>(null);

  return <ComponentInputBase {...props} ref={componentInputBaseRef} />;
};

export default ComponentInputDocument;
