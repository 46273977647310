import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const Row = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
`;

export const ListTypes = styled.div`
  align-items: center;
  background-color: ${colors.background};
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 2.25rem;
  margin-top: 1rem;
  padding: 0 0.5rem;
  width: 18rem;
`;

interface IListTypeProps {
  invalid?: boolean;
  isSelected?: boolean;
}

export const ListType = styled.button<IListTypeProps>`
  align-items: center;
  background-color: ${props =>
    props.isSelected ? colors.green900 : colors.transparent};
  border: 0;
  border-radius: 0.5rem;
  color: ${props => (props.isSelected ? colors.white900 : colors.gray300)};
  column-gap: 0.25rem;
  display: flex;
  font-size: 0.8rem;
  height: 1.5rem;
  justify-content: center;

  ${props =>
    props.invalid &&
    css`
      background-color: ${props.isSelected
        ? colors.red500
        : colors.transparent};
    `};

  &:hover {
    color: ${colors.gray200};
  }
`;

export const UploadActions = styled.div`
  column-gap: 0.5rem;
  display: flex;
  margin-left: auto;
  max-width: 22rem;
  width: 22rem;
`;

export const DownloadSpreadsheetModel = styled.a`
  width: 100%;
`;

export const UploadFile = styled.input`
  display: none;
`;

export const Actions = styled.div`
  margin: 0 1rem 1rem auto;
  width: 14rem;
`;
