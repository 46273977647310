import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { FiCamera } from 'react-icons/fi';

import EPdfType from 'enums/pdfType';
import formatters from 'formatters';
import { Formik, FormikProps } from 'formik';
import helpers from 'helpers';
import validators from 'validators';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentHeader from 'components/Header';
import ComponentInputDocument from 'components/input/Document';
import ComponentInputRadio from 'components/input/Radio';
import ComponentInputSimple from 'components/input/Simple';
import ComponentChangePassword, {
  IComponentChangePasswordRefProps,
} from 'components/modal/ChangePassword';
import ComponentImageCropper, {
  IComponentImageCropperRefProps,
} from 'components/modal/Cropper';
import ComponentIsVisible from 'components/utils/IsVisible';

import authSelectors from 'store/slices/auth/selectors';
import { imageActions } from 'store/slices/image';
import imageSelectors from 'store/slices/image/selectors';
import { settingsActions } from 'store/slices/settings';
import settingsSelectors from 'store/slices/settings/selectors';

import withoutPicture from 'assets/utils/without-picture.png';

import colors from 'styles/colors';
import Containers from 'styles/containers';
import Forms from 'styles/forms';

import {
  Actions,
  AddImageButton,
  Fields,
  ImageContent,
  ImageLabel,
  PdfLabel,
  PreviewImage,
  SettingsContent,
  Thumbnail,
  UploadFile,
} from './styles';

interface IUploadImageData {
  uploadRef: React.MutableRefObject<HTMLInputElement>;
}

interface ISettingsFormData {
  logo: string;
  name: string;
  pdfType: EPdfType;
}

const settingsValidationSchema = Yup.object().shape({
  name: Yup.string().required('Informe o nome'),
});

const Settings = (): JSX.Element => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const profile = useReduxSelector(authSelectors.profile);
  const uploadedImageUrl = useReduxSelector(imageSelectors.uploadUrl);
  const isLoading = useReduxSelector(settingsSelectors.editIsLoading);

  const settingsFormikRef = useRef<FormikProps<ISettingsFormData>>(null);
  const uploadImageEvent = useRef<HTMLInputElement>({} as HTMLInputElement);
  const componentChangePasswordRef =
    useRef<IComponentChangePasswordRefProps>(null);
  const componentImageCropperRef = useRef<IComponentImageCropperRefProps>(null);

  const handleUploadImage = (data: IUploadImageData) => {
    data.uploadRef.current.click();

    data.uploadRef.current.addEventListener('change', () => {
      if (data.uploadRef.current.files) {
        const file = data.uploadRef.current.files.item(0);

        if (file) {
          const fileVerified = validators.fileType(file?.type);

          if (!fileVerified) {
            toast.show({
              title: 'Formato de arquivo inválido',
              description: 'Formatos permitidos: png, jpg e jpeg',
              type: 'error',
            });
            return;
          }

          const reader = new FileReader();

          reader.readAsDataURL(file as File);

          reader.onload = event => {
            componentImageCropperRef.current?.open({
              imageUrl: event.target?.result as string,
            });
          };
        }
      }
    });
  };

  const handleRemoveImage = useCallback(() => {
    reduxDispatch(
      imageActions.setClearUrl({
        success() {
          toast.show({
            title: 'Imagem removida com sucesso',
            type: 'info',
          });
        },
      }),
    );
  }, [reduxDispatch, toast]);

  const handleUpdateSettings = useCallback(
    (data: ISettingsFormData) => {
      reduxDispatch(
        settingsActions.editRequest({
          data: {
            logo: uploadedImageUrl || data.logo,
            name: data.name,
            pdfType: data.pdfType,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            success: (message: string) => {
              toast.show({
                title: message,
                type: 'success',
              });
            },
          },
        }),
      );
    },
    [reduxDispatch, toast, uploadedImageUrl],
  );

  const handleOpenChangePassword = useCallback(() => {
    componentChangePasswordRef.current?.open();
  }, []);

  const initialValues: ISettingsFormData = {
    logo: profile.company.logo ? profile.company.logo : '',
    name: profile.company.name ? profile.company.name : '',
    pdfType: profile.company.pdfType
      ? profile.company.pdfType
      : EPdfType.Default,
  };

  const screenWidth = useMemo(() => {
    return window.innerWidth;
  }, []);

  useEffect(() => {
    return () => {
      handleRemoveImage();
    };
  }, [handleRemoveImage]);

  return (
    <Containers.Global>
      <ComponentHeader pageTitle="Configurações da empresa" />
      <Containers.Content>
        <Formik
          initialValues={initialValues}
          innerRef={settingsFormikRef}
          onSubmit={handleUpdateSettings}
          validateOnChange={false}
          validationSchema={settingsValidationSchema}
        >
          {({ errors, handleChange, setFieldValue, values }) => (
            <Forms.Content>
              <SettingsContent>
                <Fields>
                  <Forms.Grid marginBottom=".5">
                    <ComponentInputSimple
                      errorMessage={errors.name}
                      hasError={!!errors.name}
                      label="Razão social/Nome completo"
                      onChange={handleChange('name')}
                      value={values.name}
                    />
                  </Forms.Grid>
                  <Forms.Grid gridTemplateColumns="1fr 12rem">
                    <ComponentInputDocument
                      label="CNPJ/CPF"
                      readOnly
                      value={formatters.cpfOrCnpj(profile.company.document)}
                    />
                    <ComponentButtonBase
                      backgroundColor={colors.gray600}
                      onClick={handleOpenChangePassword}
                    >
                      Alterar senha
                    </ComponentButtonBase>
                  </Forms.Grid>
                  <PdfLabel>Tipo de PDF</PdfLabel>
                  <Forms.Grid gridTemplateColumns="repeat(2, 8rem)">
                    <ComponentInputRadio
                      checked={values.pdfType === EPdfType.Default}
                      fieldLabel="Padrão"
                      onChange={event =>
                        setFieldValue('pdfType', event.target.value)
                      }
                      value={EPdfType.Default}
                    />
                    <ComponentInputRadio
                      checked={values.pdfType === EPdfType.Linear}
                      fieldLabel="Linear"
                      onChange={event =>
                        setFieldValue('pdfType', event.target.value)
                      }
                      value={EPdfType.Linear}
                    />
                  </Forms.Grid>
                </Fields>
                <ImageContent>
                  <ImageLabel>Logo</ImageLabel>
                  <Thumbnail>
                    <PreviewImage
                      src={uploadedImageUrl || values.logo || withoutPicture}
                    />
                  </Thumbnail>
                  <ComponentIsVisible when={screenWidth >= 1000}>
                    <AddImageButton
                      onClick={() =>
                        handleUploadImage({
                          uploadRef: uploadImageEvent,
                        })
                      }
                      type="button"
                    >
                      Alterar imagem <FiCamera size={18} />
                      <UploadFile
                        accept="image/png, image/jpg, image/jpeg"
                        ref={uploadImageEvent}
                        type="file"
                      />
                    </AddImageButton>
                  </ComponentIsVisible>
                </ImageContent>
              </SettingsContent>
              <Actions>
                <ComponentButtonBase
                  disabled={isLoading}
                  height="2.5rem"
                  isLoading={isLoading}
                  type="submit"
                >
                  Salvar alterações
                </ComponentButtonBase>
              </Actions>
            </Forms.Content>
          )}
        </Formik>

        <ComponentChangePassword ref={componentChangePasswordRef} />
        <ComponentImageCropper ref={componentImageCropperRef} />
      </Containers.Content>
    </Containers.Global>
  );
};

export default Settings;
