import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { productActions } from '../index';
import { IRemoveProductRequestAction } from '../reducers/remove/request';

function* remove(action: PayloadAction<IRemoveProductRequestAction>) {
  try {
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.delete,
      `admins/products/${action.payload.data.id}`,
    );

    yield put(productActions.removeSuccess({ id: action.payload.data.id }));

    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(productActions.removeFailure());
  }
}

export default remove;
