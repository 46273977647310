import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

export interface IChangeStatusProductRequestAction {
  data: {
    id: number;
    isActive: boolean;
  };
  functions: {
    error(err: any): void;
    success(message: string): void;
  };
}

function changeStatusRequest(
  draft: Draft<IProductData>,
  _: PayloadAction<IChangeStatusProductRequestAction>,
) {
  draft.changeStatus.config.isLoading = true;
}

export default changeStatusRequest;
