import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { FiBox, FiCamera, FiTrash2 } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';
import validators from 'validators';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputSimple from 'components/input/Simple';
import ComponentImageCropper, {
  IComponentImageCropperRefProps,
} from 'components/modal/Cropper';
import ComponentIsVisible from 'components/utils/IsVisible';

import { imageActions } from 'store/slices/image';
import imageSelectors from 'store/slices/image/selectors';

import colors from 'styles/colors';
import Forms from 'styles/forms';

import {
  AddImageButton,
  ImageControls,
  ImageLabelSize,
  ImageLabelSizeContainer,
  Label,
  PreviewImage,
  RemoveButton,
  Thumbnail,
  UploadFile,
  WithoutImageMessage,
} from './styles';

interface IUploadImageData {
  uploadRef: React.MutableRefObject<HTMLInputElement>;
}

export interface IManufacturerFormData {
  logo: string | null;
  name: string;
}

const initialValues: IManufacturerFormData = {
  name: '',
  logo: '',
};

const manufacturerFormValidateSchema = Yup.object().shape({
  name: Yup.string().required('Informe um nome'),
});

interface IManufacturerFormProps {
  handleClose: () => void;
  isLoading: boolean;
  onSubmit: (data: IManufacturerFormData) => void;
}

const ManufacturerForm: React.ForwardRefRenderFunction<
  FormikProps<IManufacturerFormData>,
  IManufacturerFormProps
> = ({ handleClose, isLoading, onSubmit }, ref) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const uploadedImageUrl = useReduxSelector(imageSelectors.uploadUrl);

  const manufacturerFormikRef = useRef<FormikProps<IManufacturerFormData>>(
    {} as FormikProps<IManufacturerFormData>,
  );
  const uploadImageEvent = useRef<HTMLInputElement>({} as HTMLInputElement);
  const componentImageCropperRef = useRef<IComponentImageCropperRefProps>(null);

  const handleUploadImage = (data: IUploadImageData) => {
    data.uploadRef.current.click();

    data.uploadRef.current.addEventListener('change', () => {
      if (data.uploadRef.current.files) {
        const file = data.uploadRef.current.files.item(0);

        if (file) {
          if (file.size >= 5000000) {
            uploadImageEvent.current.value = '';
            toast.show({
              title: 'Tamanho de imagem inválido',
              description: 'Tamanho máximo da imagem 5MB',
              type: 'error',
            });
            return;
          }

          const fileVerified = validators.fileType(file?.type);
          if (!fileVerified) {
            toast.show({
              title: 'Formato de arquivo inválido',
              description: 'Formatos permitidos: png, jpg e jpeg',
              type: 'error',
            });
            return;
          }
          const reader = new FileReader();
          reader.readAsDataURL(file as File);
          reader.onload = event => {
            componentImageCropperRef.current?.open({
              imageUrl: event.target?.result as string,
            });
          };
        }
      }
    });
  };

  const handleRemoveImage = useCallback(() => {
    manufacturerFormikRef.current?.setFieldValue('logo', '');
    reduxDispatch(
      imageActions.setClearUrl({
        success() {
          toast.show({
            title: 'Imagem removida com sucesso',
            type: 'info',
          });
        },
      }),
    );
  }, [toast, reduxDispatch]);

  const screenWidth = useMemo(() => {
    return window.innerWidth;
  }, []);

  useEffect(() => {
    return () => {
      handleRemoveImage();
    };
  }, [handleRemoveImage]);

  useImperativeHandle(ref, () => ({
    ...manufacturerFormikRef.current,
  }));

  return (
    <Formik
      initialValues={initialValues}
      innerRef={manufacturerFormikRef}
      onSubmit={onSubmit}
      validateOnChange={false}
      validationSchema={manufacturerFormValidateSchema}
    >
      {({ errors, handleChange, values }) => (
        <Forms.Content>
          <Forms.Grid marginBottom="1">
            <ComponentInputSimple
              errorMessage={errors.name}
              hasError={!!errors.name}
              icon={FiBox}
              label="Nome do fabricante"
              onChange={handleChange('name')}
              value={values.name}
            />
          </Forms.Grid>

          <ComponentIsVisible when={screenWidth >= 1000}>
            <Forms.Grid alignItems="flex-start" gridTemplateColumns="1fr 12rem">
              <Label>Imagem</Label>
              <AddImageButton
                onClick={() =>
                  handleUploadImage({
                    uploadRef: uploadImageEvent,
                  })
                }
                type="button"
              >
                Selecionar imagem <FiCamera size={18} />
                <UploadFile
                  accept="image/png, image/jpg, image/jpeg"
                  ref={uploadImageEvent}
                  type="file"
                />
              </AddImageButton>
            </Forms.Grid>
            <ImageControls>
              <ComponentIsVisible when={!uploadedImageUrl && !values.logo}>
                <WithoutImageMessage>
                  Nenhuma imagem adicionada
                </WithoutImageMessage>
              </ComponentIsVisible>

              <ComponentIsVisible when={!!uploadedImageUrl || !!values.logo}>
                <Thumbnail>
                  <PreviewImage
                    src={
                      (values.logo as string) || (uploadedImageUrl as string)
                    }
                  />
                  <RemoveButton onClick={handleRemoveImage} type="button">
                    <FiTrash2 size={18} />
                  </RemoveButton>
                </Thumbnail>
              </ComponentIsVisible>
            </ImageControls>
            <ImageLabelSizeContainer>
              <ImageLabelSize>Tamanho máximo 5MB</ImageLabelSize>
            </ImageLabelSizeContainer>
          </ComponentIsVisible>

          <Forms.Actions gridTemplateColumns="8rem 12rem">
            <ComponentButtonBase
              backgroundColor={colors.red500}
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancelar
            </ComponentButtonBase>
            <ComponentButtonBase
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
            >
              Confirmar
            </ComponentButtonBase>
          </Forms.Actions>

          <ComponentImageCropper ref={componentImageCropperRef} />
        </Forms.Content>
      )}
    </Formik>
  );
};

export default forwardRef(ManufacturerForm);
