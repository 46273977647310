import { all, takeLatest } from 'redux-saga/effects';

import { productActions } from '../index';
import changeStatus from './changeStatus';
import checkImportSpreadsheet from './checkImportSpreadsheet';
import create from './create';
import edit from './edit';
import getAll from './getAll';
import importSpreadsheet from './importSpreadsheet';
import remove from './remove';

const productSagas = all([
  takeLatest(productActions.changeStatusRequest, changeStatus),
  takeLatest(
    productActions.checkImportSpreadsheetRequest,
    checkImportSpreadsheet,
  ),
  takeLatest(productActions.createRequest, create),
  takeLatest(productActions.editRequest, edit),
  takeLatest(productActions.getAllRequest, getAll),
  takeLatest(productActions.importSpreadsheetRequest, importSpreadsheet),
  takeLatest(productActions.removeRequest, remove),
]);

export default productSagas;
