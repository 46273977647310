import styled from 'styled-components';

import colors from 'styles/colors';
import Tables from 'styles/tables';

interface IManufacturerImportItemsHeaderProps {
  isInvalid?: boolean;
}

export const ManufacturerImportItemsHeader = styled(
  Tables.ItemsHeader,
)<IManufacturerImportItemsHeaderProps>`
  grid-template-columns: ${props =>
    props.isInvalid
      ? ' 1fr 10rem 0.7fr 8rem repeat(2, 0.7fr)'
      : '4rem 1fr 10rem 0.7fr 8rem repeat(2, 0.7fr)'};
  padding: 1.25rem;
`;

interface IManufacturerImportItemsBodyProps {
  isInvalid?: boolean;
}

export const ManufacturerImportItemsBody = styled(
  Tables.ItemsBody,
)<IManufacturerImportItemsBodyProps>`
  grid-template-columns: ${props =>
    props.isInvalid
      ? ' 1fr 10rem 0.7fr 8rem repeat(2, 0.7fr)'
      : '4rem 1fr 10rem 0.7fr 8rem repeat(2, 0.7fr)'};
  padding: 1.25rem;
`;

export const ManufacturerImportCheckbox = styled.label`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 1.25rem;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  user-select: none;
  width: 1.25rem;
  &:hover .checkboxInput ~ .checkmark {
    background-color: ${colors.gray900};
    border-color: ${colors.transparent};
    opacity: 0.7;
  }
  &:hover .checkboxInput:disabled ~ .checkmark {
    background-color: ${colors.white100};
    border-color: ${colors.gray900};
    cursor: not-allowed;
  }
`;

export const ManufacturerImportCheckboxInput = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease-in;
  width: 0;
  &:checked ~ .checkmark {
    background-color: ${colors.gray900};
    border-color: ${colors.transparent};
  }
  &:checked ~ .checkmark:after {
    opacity: 1;
  }
  &:disabled {
    cursor: not-allowed;
    .checkmark:after {
      background-color: ${colors.white100};
      border-color: ${colors.gray100};
    }
  }
`;

export const ManufacturerImportCheckboxMark = styled.span`
  background-color: ${colors.transparent};
  border: 1px solid ${colors.gray900};
  border-radius: 5px;
  height: 1.25rem;
  left: 0;
  position: absolute;
  transition: all 0.3s ease-in;
  width: 1.25rem;
  &:after {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: solid ${colors.white900};
    border-width: 0 1px 1px 0;
    content: '';
    height: 0.7rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(45deg) translateX(-60%);
    width: 0.25rem;
  }
`;
