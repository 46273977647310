import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import formatters from 'formatters';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPutEditUserRequest from 'services/httpClient/request/user/IPutEditUserRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { userActions } from '../index';
import { IEditUserRequestAction } from '../reducers/edit/request';

function* edit(action: PayloadAction<IEditUserRequestAction>) {
  try {
    const body: IPutEditUserRequest = {
      name: action.payload.data.name.trim(),
      email: action.payload.data.email.trim(),
      phone: formatters.removeMask(action.payload.data.phone).trim(),
      type: action.payload.data.type,
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      `admins/users/${action.payload.data.id}`,
      body,
    );

    yield put(
      userActions.editSuccess({
        id: action.payload.data.id,
        name: action.payload.data.name.trim(),
        email: action.payload.data.email.trim(),
        phone: formatters.removeMask(action.payload.data.phone).trim(),
        type: action.payload.data.type,
      }),
    );

    action.payload.functions.closeForm();
    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(userActions.editFailure());
  }
}

export default edit;
