import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IImageData } from '../../index';

export interface IUploadImageSuccessAction {
  url: string;
}

function uploadSuccess(
  draft: Draft<IImageData>,
  action: PayloadAction<IUploadImageSuccessAction>,
) {
  draft.upload.config.isLoading = false;
  draft.upload.url = action.payload.url;
}

export default uploadSuccess;
