import styled, { css } from 'styled-components';

import { shade } from 'polished';

import colors from './colors';

interface IPageTitleContainerProps {
  alignItems?: string;
  marginBottom?: string;
}

const PageTitleContainer = styled.div<IPageTitleContainerProps>`
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
`;

interface IPageTitleProps {
  fontSize?: string;
  hasBackButton?: boolean;
}
const PageTitle = styled.h1<IPageTitleProps>`
  align-items: center;
  color: ${colors.orange900};
  display: grid;
  flex: 1;
  font-size: ${props => (props.fontSize ? props.fontSize : '1.35')}rem;
  font-weight: 600;
  grid-template-columns: 1fr;
  ${props =>
    props.hasBackButton &&
    css`
      grid-template-columns: 2.5rem 1fr;
    `};
`;

interface IPageFilterContainerProps {
  alignItems?: string;
  marginBottom?: string;
}

const PageFilterContainer = styled.div<IPageFilterContainerProps>`
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-end')};
  display: flex;
  grid-gap: 0.5rem;
  justify-content: space-between;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
`;

interface IPageActionsProps {
  gridTemplateColumns?: string;
}

const PageActions = styled.div<IPageActionsProps>`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: ${props =>
    props.gridTemplateColumns ? props.gridTemplateColumns : '10rem 12rem'};
`;

interface IPageActionProps {
  backgroundColor?: string;
}

const PageAction = styled.button<IPageActionProps>`
  align-items: center;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : colors.cyan900};
  border: 0;
  border-radius: 0.375rem;
  color: ${props => (props.color ? props.color : colors.white900)};
  display: flex;
  font-size: 0.925rem;
  font-weight: 500;
  height: 2rem;
  justify-content: center;
  width: 100%;

  & svg {
    color: ${props => (props.color ? props.color : colors.white900)};
    margin-left: 1rem;
  }

  &:hover {
    background-color: ${props =>
      shade(
        0.2,
        props.backgroundColor ? props.backgroundColor : colors.cyan900,
      )};
  }

  &:disabled {
    background-color: ${colors.gray200};
    opacity: 1;
  }
`;

export default {
  PageTitleContainer,
  PageTitle,
  PageFilterContainer,
  PageActions,
  PageAction,
};
