import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

export interface IChangeStatusManufacturerSuccessAction {
  id: number;
}

function changeStatusSuccess(
  draft: Draft<IManufacturerData>,
  action: PayloadAction<IChangeStatusManufacturerSuccessAction>,
) {
  draft.changeStatus.config.isLoading = false;
  const manufacturerIndex = draft.getAll.list.findIndex(
    currentManufacturer => currentManufacturer.id === action.payload.id,
  );
  const manufacturerToChangeStatus = draft.getAll.list[manufacturerIndex];
  const manufacturerChangedStatus = {
    ...manufacturerToChangeStatus,
    isActive: !manufacturerToChangeStatus.isActive,
  };
  draft.getAll.list[manufacturerIndex] = manufacturerChangedStatus;
}

export default changeStatusSuccess;
