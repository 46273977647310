import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { userActions } from '../index';
import { IChangeStatusUserRequestAction } from '../reducers/changeStatus/request';

function* changeStatus(action: PayloadAction<IChangeStatusUserRequestAction>) {
  try {
    let message = '';
    if (!action.payload.data.isActive) {
      const response: AxiosResponse<IHttpClientSuccess> = yield call(
        httpClient.post,
        `admins/users/${action.payload.data.id}/enable`,
      );
      message = response.data.message;
    } else {
      const response: AxiosResponse<IHttpClientSuccess> = yield call(
        httpClient.post,
        `admins/users/${action.payload.data.id}/disable`,
      );
      message = response.data.message;
    }

    yield put(userActions.changeStatusSuccess({ id: action.payload.data.id }));

    action.payload.functions.success(message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(userActions.changeStatusFailure());
  }
}

export default changeStatus;
