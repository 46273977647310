import { useCallback, useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';

import AppProvider from 'hooks';
import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentAsideMenu from 'components/AsideMenu';
import ComponentIsVisible from 'components/utils/IsVisible';
import ToastManager from 'components/utils/Toast/ToastManager';

import ManufacturerImportWarning from 'pages/Manufacturer/Import/Warning';
import ProductImportWarning from 'pages/Product/Import/Warning';

import Routes from 'routes';
import ErrorBoundary from 'routes/ErrorBoundary';

import Redirect from 'services/httpClient/Redirect';

import authSelectors from 'store/slices/auth/selectors';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';
import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';

import Containers from 'styles/containers';
import GlobalStyle from 'styles/global';

const App: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const isSigned = useReduxSelector(authSelectors.token);
  const importSpreadsheetId = useReduxSelector(
    productSelectors.importSpreadsheetId,
  );
  const checkImportSpreadsheetInBackground = useReduxSelector(
    productSelectors.importSpreadsheetInBackground,
  );
  const manufacuterImportSpreadsheetIsLoading = useReduxSelector(
    manufacturerSelectors.importSpreadsheetIsLoading,
  );

  const checkImportSpreadsheet = useCallback(() => {
    if (!importSpreadsheetId) {
      return;
    }
    reduxDispatch(
      productActions.checkImportSpreadsheetRequest({
        data: {
          id: importSpreadsheetId,
        },
        functions: {
          error: (err: string) => {
            ToastManager.show({
              title: err,
              type: 'error',
            });
          },
          success: (message: string) => {
            ToastManager.show({
              title: message,
              type: 'success',
            });
          },
        },
      }),
    );
  }, [importSpreadsheetId, reduxDispatch]);

  useEffect(() => {
    if (checkImportSpreadsheetInBackground) {
      checkImportSpreadsheet();
    }
  }, [checkImportSpreadsheet, checkImportSpreadsheetInBackground]);
  return (
    <AppProvider>
      <ErrorBoundary>
        <BrowserRouter>
          <Containers.Main isSigned={!!isSigned}>
            <ComponentIsVisible when={!!isSigned}>
              <ComponentAsideMenu />
            </ComponentIsVisible>
            <Routes />

            <ComponentIsVisible when={checkImportSpreadsheetInBackground}>
              <ProductImportWarning />
            </ComponentIsVisible>
            <ComponentIsVisible when={manufacuterImportSpreadsheetIsLoading}>
              <ManufacturerImportWarning />
            </ComponentIsVisible>
          </Containers.Main>
        </BrowserRouter>
      </ErrorBoundary>

      <Redirect />
      <GlobalStyle />
    </AppProvider>
  );
};

export default App;
