import { useCallback, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';

import helpers from 'helpers';
import IProductImport from 'models/ProductImport';
import readXlsxFile from 'read-excel-file';
import validators from 'validators';
import * as Yup from 'yup';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentHeader from 'components/Header';
import ComponentEmpty from 'components/utils/Empty';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import { productActions } from 'store/slices/product';

import spreadsheetModal from 'assets/csvs/modelo_importacao_produtos.xlsx';

import colors from 'styles/colors';
import Containers from 'styles/containers';
import Tables from 'styles/tables';

import ProductImportItem from './Item';
import {
  Actions,
  DownloadSpreadsheetModel,
  ListType,
  ListTypes,
  Row,
  UploadActions,
  UploadFile,
} from './styles';

enum EListType {
  Invalid = 'Invalid',
  Valid = 'Valid',
}

interface IImportSpreadsheet {
  uploadRef: React.MutableRefObject<HTMLInputElement>;
}

export interface IProductImportWithSelected extends IProductImport {
  selected: boolean;
}

interface IProductImportUntranslated {
  apresentacao?: string;
  codigo_barras?: string;
  fabricante: string;
  nome_produto: string;
  outras_informacoes?: string;
  sku?: string;
}

export const importProductsSchemaValidation = Yup.array(
  Yup.object().shape({
    name: Yup.string().required('Descrição do produto não pode ser vazia'),
    ean: Yup.string()
      .max(20, "Tamanho máximo do campo 'ean' deve ser 20")
      .test(
        'eanOnlyNumbers',
        "O campo 'ean' deve possuir apenas números",
        ean => {
          if (ean) {
            const isEanValid = validators.onlyNumbers(ean);
            return isEanValid;
          }
          return true;
        },
      ),
    sku: Yup.string().max(25, "Tamanho máximo do campo 'SKU' deve ser 25"),
  }),
);

const ProductImport = (): JSX.Element => {
  const toast = useToast();
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();

  const [listSelected, setListSelected] = useState<EListType>(EListType.Valid);
  const [isImportProductLoading, setIsImportProductLoading] =
    useState<boolean>(false);
  const [productsValid, setProductsValid] = useState<
    IProductImportWithSelected[]
  >([]);
  const [productsInvalid, setProductsInvalid] = useState<
    IProductImportWithSelected[]
  >([]);

  const listRef = useRef<List>(null);
  const uploadSpreadsheetInputRef = useRef<HTMLInputElement>(
    {} as HTMLInputElement,
  );

  const handleChangeListType = useCallback((listType: EListType) => {
    setListSelected(listType);
  }, []);

  const handleReadFile = useCallback(
    (data: IImportSpreadsheet) => {
      data.uploadRef.current.click();

      data.uploadRef.current.addEventListener('change', async () => {
        if (data.uploadRef.current.files) {
          const file = data.uploadRef.current.files.item(0);
          if (file) {
            const fileVerified = validators.importFileType(file?.type);

            if (!fileVerified) {
              toast.show({
                title: 'Formato de arquivo inválido',
                description: 'Formatos permitidos: .xlsx, .xls e .csv',
                type: 'error',
              });
              return;
            }

            const element = document.getElementById('importList');
            window.scroll(0, element?.offsetTop || 0);
            setIsImportProductLoading(true);

            const rowsRead = await readXlsxFile(file);
            rowsRead.shift();

            const productsFormattedValid = [];
            const productsFormattedNotValid = [];

            for (let row = 0; row < rowsRead.length; row += 1) {
              const productFormatted = {
                name: rowsRead[row][0]?.toString().toUpperCase() || undefined,
                ean: rowsRead[row][1]?.toString() || undefined,
                manufacturer:
                  rowsRead[row][2]?.toString().toUpperCase() || undefined,
                sku: rowsRead[row][3]?.toString().toUpperCase() || undefined,
                description:
                  rowsRead[row][4]?.toString().toUpperCase() || undefined,
                otherInfo:
                  rowsRead[row][5]?.toString().toUpperCase() || undefined,
                selected: true,
              } as IProductImportWithSelected;

              if (
                productFormatted.name === undefined ||
                productFormatted.name.length > 100 ||
                productFormatted.name.length < 1 ||
                (productFormatted.sku && productFormatted.sku.length > 25) ||
                (productFormatted.ean &&
                  (productFormatted.ean.length > 20 ||
                    /[^0-9]/g.test(productFormatted.ean))) ||
                productFormatted.manufacturer === undefined ||
                productFormatted.manufacturer.length > 100 ||
                productFormatted.manufacturer.length < 1
              ) {
                productsFormattedNotValid.push(productFormatted);
              } else {
                productsFormattedValid.push(productFormatted);
              }
            }

            setProductsValid(productsFormattedValid);
            setProductsInvalid(productsFormattedNotValid);

            setIsImportProductLoading(false);
          }
        }
      });
    },
    [toast],
  );

  const handleToggleSelected = useCallback(
    (index: number) => {
      productsValid[index].selected = !productsValid[index].selected;
      listRef.current?.forceUpdateGrid();
    },
    [productsValid],
  );

  const handleSendProduts = useCallback(() => {
    const productsSelected = productsValid.filter(
      productValid => productValid.selected,
    );

    const productsFormatted: IProductImportUntranslated[] =
      productsSelected.map(product => ({
        nome_produto: product.name.toUpperCase(),
        outras_informacoes: product.otherInfo || '',
        codigo_barras: product.ean,
        sku: product.sku?.toUpperCase() || '',
        fabricante: product.manufacturer,
        apresentacao: product.description ? product.description : '',
      }));

    reduxDispatch(
      productActions.importSpreadsheetRequest({
        data: {
          products: productsFormatted,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
          success: (message: string) => {
            setProductsInvalid([]);
            setProductsValid([]);
            toast.show({
              title: message,
              type: 'success',
            });
            listRef.current?.forceUpdateGrid();
          },
          goBack: () => {
            navigate(pages.product.list, { replace: true });
          },
        },
      }),
    );
  }, [navigate, productsValid, reduxDispatch, toast]);

  const MemoizedComponentEmpty = useCallback(
    () => (
      <ComponentEmpty
        message="Selecione um arquivo para carregar as informações"
        show={!productsValid.length && !productsInvalid.length}
      />
    ),
    [productsInvalid.length, productsValid.length],
  );

  const MemoizedProductItemBody = useCallback(
    ({ index, key, style }) => (
      <ProductImportItem.Body
        handleToggleSelected={handleToggleSelected}
        index={index}
        isInvalid={listSelected === EListType.Invalid}
        key={key}
        products={
          listSelected === EListType.Valid ? productsValid : productsInvalid
        }
        style={style}
      />
    ),
    [handleToggleSelected, listSelected, productsInvalid, productsValid],
  );

  return (
    <Containers.Global>
      <ComponentHeader hasBackButton pageTitle="Importar produtos" />
      <Containers.Content>
        <Row>
          <ListTypes>
            <ListType
              isSelected={listSelected === EListType.Valid}
              onClick={() => handleChangeListType(EListType.Valid)}
            >
              {`Válidos (${productsValid.length})`}
            </ListType>
            <ListType
              invalid
              isSelected={listSelected === EListType.Invalid}
              onClick={() => handleChangeListType(EListType.Invalid)}
            >
              {`Inválidos (${productsInvalid.length})`}
            </ListType>
          </ListTypes>
          <UploadActions>
            <DownloadSpreadsheetModel
              download="modelo-planilha.xlsx"
              href={spreadsheetModal}
              rel="noreferrer"
              target="_blank"
            >
              <ComponentButtonBase backgroundColor={colors.gray600}>
                Baixar modelo
              </ComponentButtonBase>
            </DownloadSpreadsheetModel>
            <ComponentButtonBase
              onClick={() =>
                handleReadFile({
                  uploadRef: uploadSpreadsheetInputRef,
                })
              }
            >
              Selecionar arquivo
              <UploadFile
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ref={uploadSpreadsheetInputRef}
                type="file"
              />
            </ComponentButtonBase>
          </UploadActions>
        </Row>

        <Tables.Items id="importList">
          <ProductImportItem.Header
            isInvalid={listSelected === EListType.Invalid}
          />

          <ComponentLoadingList show={isImportProductLoading} />

          <ComponentIsVisible when={!isImportProductLoading}>
            <ComponentIsVisible when={listSelected === EListType.Valid}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    className="virtualizedListScrollBar"
                    height={50 * 10}
                    noRowsRenderer={MemoizedComponentEmpty}
                    overscanRowCount={10}
                    ref={listRef}
                    rowCount={productsValid.length}
                    rowHeight={80}
                    rowRenderer={MemoizedProductItemBody}
                    width={width}
                  />
                )}
              </AutoSizer>

              <Actions>
                <ComponentButtonBase
                  backgroundColor={colors.orange900}
                  disabled={!productsValid.length}
                  onClick={handleSendProduts}
                >
                  Confirmar importação
                </ComponentButtonBase>
              </Actions>
            </ComponentIsVisible>

            <ComponentIsVisible when={listSelected === EListType.Invalid}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    className="virtualizedListScrollBar"
                    height={50 * 10}
                    noRowsRenderer={MemoizedComponentEmpty}
                    overscanRowCount={10}
                    ref={listRef}
                    rowCount={productsInvalid.length}
                    rowHeight={80}
                    rowRenderer={MemoizedProductItemBody}
                    width={width}
                  />
                )}
              </AutoSizer>
            </ComponentIsVisible>
          </ComponentIsVisible>
        </Tables.Items>
      </Containers.Content>
    </Containers.Global>
  );
};

export default ProductImport;
