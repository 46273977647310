import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

interface IEditProductImageSuccessAction {
  isActive: boolean;
  sort: number;
  url: string;
}

interface IEditProductSuccessAction {
  amount?: string;
  description?: string;
  ean: string;
  id: number;
  images: IEditProductImageSuccessAction[];
  manufacturerId: number;
  name: string;
  otherInfo?: string;
  sku: string;
}

function editSuccess(
  draft: Draft<IProductData>,
  action: PayloadAction<IEditProductSuccessAction>,
) {
  draft.edit.config.isLoading = false;
  const productIndex = draft.getAll.list.findIndex(
    product => product.id === action.payload.id,
  );
  const productToEdit = draft.getAll.list[productIndex];
  draft.getAll.list[productIndex] = {
    ...productToEdit,
    name: action.payload.name,
    barcode: action.payload.ean,
    description: action.payload.description,
    manufacturerId: action.payload.manufacturerId,
    sku: action.payload.sku,
    otherInfo: action.payload.otherInfo,
    images: action.payload.images,
    amount: action.payload.amount ? action.payload.amount : undefined,
  };
}

export default editSuccess;
