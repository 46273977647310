import styled from 'styled-components';

import Tables from 'styles/tables';

export const ProductItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 5rem 6rem 8rem 20rem 15rem 4rem 8rem;
  padding: 1.25rem 1rem;

  @media screen and (min-width: 1240px) {
    grid-template-columns: 6rem 7rem 10rem 1fr 15rem repeat(2, 8rem);
  }
`;

export const ProductItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 5rem 6rem 8rem 20rem 15rem 4rem 8rem;
  padding: 1rem;

  @media screen and (min-width: 1240px) {
    grid-template-columns: 6rem 7rem 10rem 1fr 15rem repeat(2, 8rem);
  }
`;
