import ComponentIsVisible from 'components/utils/IsVisible';

import Tables from 'styles/tables';

import { ManufacturerImportItemsHeader } from '../styles';

interface IItemHeaderProps {
  isInvalid?: boolean;
}

const ItemHeader = ({ isInvalid }: IItemHeaderProps): JSX.Element => {
  return (
    <ManufacturerImportItemsHeader isInvalid={isInvalid}>
      <ComponentIsVisible when={!isInvalid}>
        <Tables.ItemHeader center />
      </ComponentIsVisible>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
    </ManufacturerImportItemsHeader>
  );
};

export default ItemHeader;
