import styled, { css } from 'styled-components';

import { shade } from 'polished';

import colors from 'styles/colors';

export const Label = styled.label`
  color: ${colors.gray300};
  display: flex;
  font-size: 0.75rem;
`;

export const AddImageButton = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  border-radius: 0.375rem;
  color: ${colors.cyan900};
  column-gap: 1rem;
  display: flex;
  font-size: 0.75rem;
  justify-content: flex-end;
  text-decoration: underline;
  width: 100%;

  &:hover {
    opacity: 0.6;
  }
`;

export const ImageLabelSize = styled.span`
  align-self: center;
  color: ${colors.gray300};
  display: flex;
  font-size: 0.75rem;
`;

export const ImageLabelSizeContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  width: 100%;
`;
export const UploadFile = styled.input`
  display: none;
`;

export const ThumbnailBody = styled.div`
  column-gap: 0.5rem;
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  position: relative;
  width: 100%;
`;

export const WithoutImageMessage = styled.span`
  align-items: center;
  border: 1px solid ${colors.gray100};
  border-radius: 0.375rem;
  color: ${colors.gray300};
  display: flex;
  font-size: 0.75rem;
  height: 9rem;
  justify-content: center;
  margin: 1rem auto 0;
  text-align: center;
  width: 9rem;
`;

export const DraggableContent = styled.div``;

interface IThumbnailProps {
  isDragging?: boolean;
}

export const Thumbnail = styled.div<IThumbnailProps>`
  align-items: center;
  border-radius: 0.375rem;
  display: flex;
  height: 9rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: all 0.1s ease-in-out;
  width: 9rem;

  ${props =>
    props.isDragging &&
    css`
      transform: scale(1.1) !important;
      transform: rotateZ(8deg) !important;
    `};
`;

export const PreviewImage = styled.img`
  border: 1px solid ${colors.gray100};
  border-radius: 0.375rem;
  height: 100%;
  width: 100%;
`;

export const RemoveButton = styled.button`
  align-items: center;
  background-color: ${colors.red500};
  border: 0;
  border-radius: 0.375rem;
  bottom: 0.5rem;
  color: ${colors.white900};
  display: flex;
  font-size: 0;
  height: 2rem;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  width: 2rem;

  &:hover {
    background-color: ${shade(0.2, colors.red500)};
  }
`;

interface IDraggableMessage {
  show: boolean;
}

export const DraggableMessage = styled.span<IDraggableMessage>`
  color: ${colors.gray300};
  display: ${props => (props.show ? 'flex' : 'none')};
  font-size: 0.75rem;
  justify-content: center;
  margin-top: 1rem;
  text-align: center;
  width: 100%;
`;
