import styled from 'styled-components';

import { shade } from 'polished';

import colors from 'styles/colors';

export const Aside = styled.aside`
  height: 100vh;
  left: 0;
  max-width: 10rem;
  position: fixed;
  top: 0;
  width: 100%;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  position: relative;
  ::-webkit-scrollbar {
    background: ${shade(0.4, colors.gray900)};
    height: 0.5rem;
    width: 0.4rem;
  }

  ::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: ${shade(0.1, colors.gray900)};
  }

  ::-webkit-scrollbar-thumb {
    background: ${shade(0.4, colors.gray900)};
    border-radius: 0 0 0.25rem 0.25rem;
    height: 0.5rem;
    width: 0.4rem;
  }
`;

export const Thumbnail = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  max-height: 5rem;
  max-width: 5rem;
`;

export const Logo = styled.img`
  height: 100%;
  width: 100%;
`;

export const Navigation = styled.nav`
  display: grid;
  margin-bottom: 2rem;
  row-gap: 3rem;
  width: 100%;
`;

export const ExternalLink = styled.a``;
