import { createSlice } from '@reduxjs/toolkit';
import IPagination from 'interfaces/IPagination';
import IUser from 'models/User';

import reducers from './reducers';

export interface IUserData {
  changePassword: {
    config: {
      isLoading: boolean;
    };
  };
  changeStatus: {
    config: {
      isLoading: boolean;
    };
  };
  create: {
    config: {
      isLoading: boolean;
    };
  };
  edit: {
    config: {
      isLoading: boolean;
    };
  };
  getAll: {
    config: {
      emptyMessage: string;
      errorMessage: string;
      isLoading: boolean;
      pagination: IPagination;
    };
    list: IUser[];
  };
}

const initialState: IUserData = {
  changePassword: {
    config: {
      isLoading: false,
    },
  },
  changeStatus: {
    config: {
      isLoading: false,
    },
  },
  create: {
    config: {
      isLoading: false,
    },
  },
  edit: {
    config: {
      isLoading: false,
    },
  },
  getAll: {
    config: {
      emptyMessage: 'Nenhum usuário encontrado',
      errorMessage: '',
      isLoading: false,
      pagination: {
        page: 1,
        totalPages: 1,
      },
    },
    list: [],
  },
};

const userSlice = createSlice({
  name: '@user',
  initialState,
  reducers,
});

export const userActions = userSlice.actions;
export const userReducers = userSlice.reducer;
