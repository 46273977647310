import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { FormikProps } from 'formik';
import helpers from 'helpers';
import IManufacturer from 'models/Manufacturer';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentHeader from 'components/Header';
import ComponentAlert, {
  IComponentAlertRefProps,
} from 'components/modal/Alert';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import { manufacturerActions } from 'store/slices/manufacturer';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';

import colors from 'styles/colors';
import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import ManufacturerCreate, { IManufacturerCreateRefProps } from '../Create';
import ManufacturerEdit, { IManufacturerEditRefProps } from '../Edit';
import ManufacturerFilter, { IManufacturerFilterData } from './Filter';
import ManufacturerItem from './Item';

const ManufacturerList = (): JSX.Element => {
  const toast = useToast();
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();
  const manufacturers = useReduxSelector(manufacturerSelectors.getAllList);
  const pagination = useReduxSelector(manufacturerSelectors.getAllPagination);
  const isLoading = useReduxSelector(manufacturerSelectors.getAllIsLoading);
  const emptyMessage = useReduxSelector(
    manufacturerSelectors.getAllEmptyMessage,
  );
  const errorMessage = useReduxSelector(
    manufacturerSelectors.getAllErrorMessage,
  );
  const changeStatusIsLoading = useReduxSelector(
    manufacturerSelectors.changeStatusIsLoading,
  );

  const manufacturerFilterRef =
    useRef<FormikProps<IManufacturerFilterData>>(null);
  const manufacturerCreateRef = useRef<IManufacturerCreateRefProps>(null);
  const manufacturerEditRef = useRef<IManufacturerEditRefProps>(null);
  const componentAlertRef = useRef<IComponentAlertRefProps>(null);

  const handleLoadManufacturers = useCallback(() => {
    reduxDispatch(
      manufacturerActions.getAllRequest({
        data: {
          page: 1,
        },
        functions: {
          error(err) {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handlePagination = useCallback(
    page => {
      reduxDispatch(
        manufacturerActions.getAllRequest({
          data: {
            page,
            name: manufacturerFilterRef.current?.values.name,
          },
          functions: {
            error(err) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleFilter = useCallback(
    async (data: IManufacturerFilterData) => {
      reduxDispatch(
        manufacturerActions.getAllRequest({
          data: {
            page: 1,
            name: data.name,
          },
          functions: {
            error(err: any) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleClearFilter = useCallback(() => {
    manufacturerFilterRef.current?.resetForm();
    manufacturerFilterRef.current?.setErrors({});
    reduxDispatch(
      manufacturerActions.getAllRequest({
        data: {
          page: 1,
        },
        functions: {
          error(err: any) {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handleOpenCreateManufacturer = useCallback(() => {
    manufacturerCreateRef.current?.open();
  }, []);

  const handleOpenEditManufacturer = useCallback((data: IManufacturer) => {
    manufacturerEditRef.current?.open(data);
  }, []);

  const handleOpenChangeStatusAlert = useCallback((data: IManufacturer) => {
    componentAlertRef.current?.open({
      id: data.id,
      name: data.name,
      isActive: data.isActive,
    });
  }, []);

  const handleChangeManufacturerStatus = useCallback(
    (data: IManufacturer) => {
      reduxDispatch(
        manufacturerActions.changeStatusRequest({
          data: {
            id: data.id,
            isActive: data.isActive,
          },
          functions: {
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            error(err: any) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
      componentAlertRef.current?.close();
    },
    [reduxDispatch, toast],
  );

  const openManufacturerImport = useCallback(() => {
    navigate(pages.manufacturer.import);
  }, [navigate]);

  const screenWidth = useMemo(() => {
    return window.innerWidth;
  }, []);

  useEffect(() => {
    handleLoadManufacturers();
  }, [handleLoadManufacturers]);

  return (
    <Containers.Global>
      <ComponentHeader pageTitle="Fabricantes" />
      <Containers.Content>
        <General.PageFilterContainer>
          <ManufacturerFilter
            isLoading={isLoading}
            onClear={handleClearFilter}
            onSubmit={handleFilter}
            ref={manufacturerFilterRef}
          />

          <General.PageActions
            gridTemplateColumns={screenWidth >= 1000 ? '12rem 10rem' : '10rem'}
          >
            <ComponentIsVisible when={screenWidth >= 1000}>
              <General.PageAction
                backgroundColor={colors.orange900}
                onClick={openManufacturerImport}
              >
                Importar fabricantes
              </General.PageAction>
            </ComponentIsVisible>
            <General.PageAction onClick={handleOpenCreateManufacturer}>
              Novo fabricante
            </General.PageAction>
          </General.PageActions>
        </General.PageFilterContainer>

        <Tables.Items>
          <ManufacturerItem.Header />

          <ComponentLoadingList show={isLoading} />

          <ComponentIsVisible when={!isLoading}>
            <ComponentIsVisible when={!!manufacturers?.length}>
              {manufacturers?.map((manufacturer, position) => (
                <ManufacturerItem.Body
                  key={manufacturer.id}
                  manufacturer={manufacturer}
                  openChangeStatusAlert={handleOpenChangeStatusAlert}
                  openEdit={handleOpenEditManufacturer}
                  position={position}
                />
              ))}
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={!manufacturers?.length && !errorMessage}>
            <ComponentEmpty message={emptyMessage} show={!!emptyMessage} />
          </ComponentIsVisible>

          <ComponentIsVisible when={!!errorMessage}>
            <ComponentError
              message={errorMessage}
              onClick={handleLoadManufacturers}
            />
          </ComponentIsVisible>
        </Tables.Items>

        <ComponentPaginate
          currentPage={pagination.page}
          onPage={handlePagination}
          show={!!manufacturers?.length}
          totalPages={pagination.totalPages}
        />

        <ManufacturerCreate
          ref={manufacturerCreateRef}
          reloadManufacturers={handleLoadManufacturers}
        />
        <ManufacturerEdit ref={manufacturerEditRef} />

        <ComponentAlert
          isLoading={changeStatusIsLoading}
          onConfirm={handleChangeManufacturerStatus}
          ref={componentAlertRef}
        />
      </Containers.Content>
    </Containers.Global>
  );
};

export default ManufacturerList;
