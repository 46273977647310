import { forwardRef, useImperativeHandle, useRef } from 'react';

import { FaRegAddressCard } from 'react-icons/fa';
import { FiMail, FiPhone, FiUserCheck } from 'react-icons/fi';
import { SingleValue } from 'react-select';

import EUserTypes from 'enums/userTypes';
import formatters from 'formatters';
import { Formik, FormikProps } from 'formik';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import * as Yup from 'yup';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPhone from 'components/input/Phone';
import ComponentSelectSimple, {
  OptionTypeBase,
} from 'components/input/select/Simple';
import ComponentInputSimple from 'components/input/Simple';

import colors from 'styles/colors';
import Forms from 'styles/forms';

export const userTypeOptions: IComponentSelectProps<EUserTypes>[] = [
  {
    label: 'Admin',
    value: EUserTypes.Admin,
  },
  {
    label: 'Visualização',
    value: EUserTypes.Preview,
  },
  {
    label: 'Integração',
    value: EUserTypes.Integration,
  },
];

export interface IUserFormData {
  email: string;
  name: string;
  phone: string;
  type: IComponentSelectProps<EUserTypes>;
}

const initialValues: IUserFormData = {
  name: '',
  email: '',
  phone: '',
  type: {
    label: 'Admin',
    value: EUserTypes.Admin,
  },
};

const UserFormValidateSchema = Yup.object().shape({
  name: Yup.string().required('Informe um nome'),
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required('Informe um e-mail'),
  phone: Yup.string()
    .required("O campo 'Telefone' é obrigatório")
    .min(14, "Tamanho mínimo do campo 'Telefone' deve ser 14 caracteres")
    .max(15, "Tamanho máximo do campo 'Telefone' deve ser 15 caracteres"),
  type: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .typeError('Selecione o tipo de usuário'),
});

interface IUserFormProps {
  handleClose: () => void;
  isLoading: boolean;
  onSubmit: (data: IUserFormData) => void;
}

const UserForm: React.ForwardRefRenderFunction<
  FormikProps<IUserFormData>,
  IUserFormProps
> = ({ handleClose, isLoading, onSubmit }, ref) => {
  const userFormikRef = useRef<FormikProps<IUserFormData>>(
    {} as FormikProps<IUserFormData>,
  );

  useImperativeHandle(ref, () => ({
    ...userFormikRef.current,
  }));

  return (
    <Formik
      initialValues={initialValues}
      innerRef={userFormikRef}
      onSubmit={onSubmit}
      validateOnChange={false}
      validationSchema={UserFormValidateSchema}
    >
      {({ errors, handleChange, setFieldValue, values }) => (
        <Forms.Content marginTop="1">
          <Forms.Grid marginBottom="0.5">
            <ComponentInputSimple
              errorMessage={errors.name}
              hasError={!!errors.name}
              icon={FaRegAddressCard}
              label="Nome do usuário"
              onChange={handleChange('name')}
              value={values.name}
            />
            <ComponentInputSimple
              errorMessage={errors.email}
              hasError={!!errors.email}
              icon={FiMail}
              label="E-mail"
              onChange={handleChange('email')}
              value={values.email}
            />
          </Forms.Grid>

          <Forms.Grid gridTemplateColumns="repeat(2, 1fr)">
            <ComponentInputPhone
              errorMessage={errors.phone}
              hasError={!!errors.phone}
              icon={FiPhone}
              label="Telefone"
              onChange={event =>
                setFieldValue('phone', formatters.phone(event.target.value))
              }
              value={values.phone}
            />

            <ComponentSelectSimple
              errorMessage={errors.type?.value}
              hasError={!!errors.type}
              icon={FiUserCheck}
              label="Tipo de usuário"
              onChange={(option: SingleValue<OptionTypeBase>) =>
                setFieldValue('type', option)
              }
              options={userTypeOptions}
              value={values.type}
            />
          </Forms.Grid>

          <Forms.Actions gridTemplateColumns="8rem 12rem">
            <ComponentButtonBase
              backgroundColor={colors.red500}
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancelar
            </ComponentButtonBase>
            <ComponentButtonBase
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
            >
              Confirmar
            </ComponentButtonBase>
          </Forms.Actions>
        </Forms.Content>
      )}
    </Formik>
  );
};

export default forwardRef(UserForm);
