const fileOptions = ['image/png', 'image/jpg', 'image/jpeg'];

function fileType(fileType: string): boolean {
  if (!fileType) {
    return false;
  }

  const fileVerified = fileOptions.includes(fileType, 0);

  return fileVerified;
}

export default fileType;
