interface INumberOptions {
  defaultValue?: string;
}

function onlyNumbers(value?: string | null, options?: INumberOptions): string {
  if (!value) {
    return options?.defaultValue || '';
  }

  const numberFormatted = value.replace(/[^0-9]/g, '');

  return numberFormatted;
}

export default onlyNumbers;
