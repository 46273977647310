import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

export interface IChangeStatusProductSuccessAction {
  id: number;
}

function changeStatusSuccess(
  draft: Draft<IProductData>,
  action: PayloadAction<IChangeStatusProductSuccessAction>,
) {
  draft.changeStatus.config.isLoading = false;
  const productIndex = draft.getAll.list.findIndex(
    currentProduct => currentProduct.id === action.payload.id,
  );
  const productToChangeStatus = draft.getAll.list[productIndex];
  const productChangedStatus = {
    ...productToChangeStatus,
    isActive: !productToChangeStatus.isActive,
  };
  draft.getAll.list[productIndex] = productChangedStatus;
}

export default changeStatusSuccess;
