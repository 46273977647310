import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostForgotPasswordRequest from 'services/httpClient/request/auth/IPostForgotPasswordRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { authActions } from '../index';
import { IForgotPasswordRequestAction } from '../reducers/forgotPassword/request';

function* forgotPassword(action: PayloadAction<IForgotPasswordRequestAction>) {
  try {
    const body: IPostForgotPasswordRequest = {
      email: action.payload.data.email,
    };

    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.post,
      'sessions/passwords/forgot',
      body,
    );

    yield put(authActions.forgotPasswordSuccess());
    action.payload.functions.success(message);
    action.payload.functions.navigateTo();
  } catch (err: any) {
    action.payload.functions.error(err);
    yield put(authActions.forgotPasswordFailure());
  }
}

export default forgotPassword;
