import {
  FiCheckCircle,
  FiEdit,
  FiSlash,
  FiTrash2,
  FiXCircle,
} from 'react-icons/fi';

import IProduct from 'models/Product';

import ComponentIsVisible from 'components/utils/IsVisible';

import withoutPicture from 'assets/utils/without-picture.png';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { ProductItemsBody } from '../styles';

interface IItemBodyProps {
  openChangeStatusAlert: (data: IProduct) => void;
  openProductEdit: (data: IProduct) => void;
  openRemoveAlert: (data: IProduct) => void;
  position: number;
  product: IProduct;
}

const ItemBody = ({
  openChangeStatusAlert,
  openProductEdit,
  openRemoveAlert,
  position,
  product,
}: IItemBodyProps): JSX.Element => {
  return (
    <ProductItemsBody addColorRow={!!(position % 2)}>
      <Tables.ItemBody center>
        <Tables.ItemPicture
          alt={
            product.images?.length ? 'Imagem do produto' : 'Produto sem imagem'
          }
          src={product.images?.length ? product.images[0].url : withoutPicture}
        />
      </Tables.ItemBody>
      <Tables.ItemBody center>{product.sku}</Tables.ItemBody>
      <Tables.ItemBody center>{product.barcode}</Tables.ItemBody>
      <Tables.ItemBody>{product.name}</Tables.ItemBody>
      <Tables.ItemBody>{product.manufacturer.name}</Tables.ItemBody>
      <Tables.ItemBody center>
        <ComponentIsVisible when={product.isActive}>
          <FiCheckCircle color={colors.orange900} size={18} title="Ativo" />
        </ComponentIsVisible>
        <ComponentIsVisible when={!product.isActive}>
          <FiXCircle color={colors.red500} size={18} title="Desativado" />
        </ComponentIsVisible>
      </Tables.ItemBody>
      <Tables.ItemBody center>
        <Tables.ItemActions>
          <Tables.ItemAction
            onClick={() => openProductEdit(product)}
            title="Editar produto"
          >
            <FiEdit color={colors.cyan900} size={18} />
          </Tables.ItemAction>
          <Tables.ItemAction onClick={() => openChangeStatusAlert(product)}>
            <ComponentIsVisible when={product.isActive}>
              <FiSlash
                color={colors.red500}
                size={18}
                title="Desativar produto"
              />
            </ComponentIsVisible>
            <ComponentIsVisible when={!product.isActive}>
              <FiCheckCircle
                color={colors.green900}
                size={18}
                title="Ativar produto"
              />
            </ComponentIsVisible>
          </Tables.ItemAction>
          <Tables.ItemAction
            onClick={() => openRemoveAlert(product)}
            title="Remover produto"
          >
            <FiTrash2 color={colors.red500} size={18} />
          </Tables.ItemAction>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </ProductItemsBody>
  );
};

export default ItemBody;
