import changePasswordIsLoading from './changePasswordIsLoading';
import changeStatusIsLoading from './changeStatusIsLoading';
import createIsLoading from './createIsLoading';
import editIsLoading from './editIsLoading';
import getAllEmptyMessage from './getAllEmptyMessage';
import getAllErrorMessage from './getAllErrorMessage';
import getAllIsLoading from './getAllIsLoading';
import getAllList from './getAllList';
import getAllPagination from './getAllPagination';

const userSelectors = {
  changePasswordIsLoading,
  changeStatusIsLoading,
  createIsLoading,
  editIsLoading,
  getAllEmptyMessage,
  getAllList,
  getAllIsLoading,
  getAllErrorMessage,
  getAllPagination,
};

export default userSelectors;
