interface IPhoneOptions {
  defaultValue?: string;
}

function phone(value?: string | null, options?: IPhoneOptions): string {
  if (!value) {
    return options?.defaultValue || '';
  }

  const phoneFormatted = value
    .replace(/[^0-9]/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4,5})(\d{4})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');

  return phoneFormatted;
}

export default phone;
