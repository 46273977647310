import { TextareaHTMLAttributes, useRef, useState } from 'react';

import { FiAlertCircle } from 'react-icons/fi';

import ComponentVisible from 'components/utils/IsVisible';

import {
  Container,
  Error,
  FieldContainer,
  IFieldContainerProps,
  Label,
  Textarea,
} from './styles';

interface ITextareaProps
  extends Omit<IFieldContainerProps, 'isErrored' | 'isFocused' | 'isFilled'>,
    Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'height'> {
  errorMessage?: string;
  hasError?: boolean;
  isIcon?: boolean;
  label?: string;
  title?: string;
}

const ComponentTextarea: React.FC<ITextareaProps> = ({
  backgroundColor,
  errorMessage,
  hasError = false,
  height,
  label,
  readOnly = false,
  title,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInputFocus = () => {
    if (!readOnly) {
      setIsFocused(true);
    }
  };
  const handleInputBlur = () => {
    if (!readOnly) {
      setIsFocused(false);
      setIsFilled(!!textareaRef.current?.value);
    }
  };

  return (
    <Container>
      <ComponentVisible when={!!label}>
        <Label>{label}</Label>
      </ComponentVisible>

      <FieldContainer
        backgroundColor={backgroundColor}
        height={height}
        isErrored={hasError}
        isFilled={isFilled}
        isFocused={isFocused}
        isReadOnly={readOnly}
      >
        <Textarea
          height={height}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          readOnly={readOnly}
          ref={textareaRef}
          title={title}
          {...rest}
        />

        <ComponentVisible when={hasError}>
          <Error title={errorMessage as string}>
            <FiAlertCircle size={18} />
          </Error>
        </ComponentVisible>
      </FieldContainer>
    </Container>
  );
};

export default ComponentTextarea;
