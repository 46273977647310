import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { FiLogOut } from 'react-icons/fi';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBack from 'components/button/Back';
import ComponentIsVisible from 'components/utils/IsVisible';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import General from 'styles/general';

import {
  Content,
  Header,
  LogoutButton,
  Small,
  UserInfo,
  UserName,
} from './styles';

interface IComponentHeaderProps {
  hasBackButton?: boolean;
  pageTitle: string;
}

const ComponentHeader = ({
  hasBackButton = false,
  pageTitle,
}: IComponentHeaderProps): JSX.Element => {
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();
  const profile = useReduxSelector(authSelectors.profile);

  const handleLogout = useCallback(() => {
    reduxDispatch(authActions.logoutRequest());

    navigate(pages.auth.signIn, { replace: true });
  }, [navigate, reduxDispatch]);
  return (
    <Header>
      <Content>
        <General.PageTitle hasBackButton={hasBackButton}>
          <ComponentIsVisible when={hasBackButton}>
            <ComponentButtonBack />
          </ComponentIsVisible>
          {pageTitle}
        </General.PageTitle>

        <UserInfo>
          <UserName>
            <Small>Bem-vindo,</Small>
            {profile.name}
          </UserName>
          <LogoutButton onClick={handleLogout} title="Sair">
            <FiLogOut size={20} />
          </LogoutButton>
        </UserInfo>
      </Content>
    </Header>
  );
};

export default ComponentHeader;
