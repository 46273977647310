import styled, { css } from 'styled-components';

import colors from 'styles/colors';

interface INavigationItemProps {
  selected?: boolean;
}

export const NavigationItem = styled.button<INavigationItemProps>`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.white900};
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 500;
  opacity: 0.4;
  position: relative;
  row-gap: 0.25rem;
  transition: all 0.3s ease;
  width: 100%;
  &:hover {
    opacity: 1;
  }

  ${props =>
    props.selected &&
    css`
      font-weight: 600;
      opacity: 1;
      &::before {
        background-color: ${colors.white900};
        border-radius: 1.25px;
        content: '';
        height: 120%;
        left: 5px;
        position: absolute;
        top: -10%;
        width: 3px;
      }
    `};
`;

export const NavigationItemIcon = styled.div``;

export const NavigationItemName = styled.label`
  cursor: pointer;

  letter-spacing: 0.05rem;
`;
