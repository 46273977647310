import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

interface IEditSuccessAction {
  id: number;
  logo: string | null;
  name: string;
}

function editSuccess(
  draft: Draft<IManufacturerData>,
  action: PayloadAction<IEditSuccessAction>,
) {
  draft.edit.config.isLoading = false;
  draft.edit.config.isLoading = false;
  const manufacturerIndex = draft.getAll.list.findIndex(
    manufacturer => manufacturer.id === action.payload.id,
  );
  const manufacturerToEdit = draft.getAll.list[manufacturerIndex];
  draft.getAll.list[manufacturerIndex] = {
    isActive: manufacturerToEdit.isActive,
    name: action.payload.name,
    id: manufacturerToEdit.id,
    logo: action.payload.logo,
    productsAmount: manufacturerToEdit.productsAmount,
  };
}

export default editSuccess;
