import { Route, Routes } from 'react-router-dom';

import pages from 'constants/pages';

import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword';
import SignIn from 'pages/Auth/SignIn';
import ManufacturerImport from 'pages/Manufacturer/Import';
import ManufacturerList from 'pages/Manufacturer/List';
import ProductImport from 'pages/Product/Import';
import ProductList from 'pages/Product/List';
import Settings from 'pages/Settings';
import UserList from 'pages/User/List';

import NotFound from './NotFound';
import PrivateRouteWrapper from './PrivateRouteWrapper';

const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<PrivateRouteWrapper />}>
        <Route element={<ProductList />} path={pages.product.list} />
        <Route element={<ProductImport />} path={pages.product.import} />

        <Route element={<ManufacturerList />} path={pages.manufacturer.list} />
        <Route
          element={<ManufacturerImport />}
          path={pages.manufacturer.import}
        />

        <Route element={<UserList />} path={pages.user.list} />

        <Route element={<Settings />} path={pages.settings} />
      </Route>
      <Route element={<SignIn />} path={pages.auth.signIn} />
      <Route element={<ForgotPassword />} path={pages.auth.forgotPassword} />
      <Route element={<ResetPassword />} path={pages.auth.resetPassword} />

      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default MainRoutes;
