import { REHYDRATE } from 'redux-persist';
import { all, takeLatest } from 'redux-saga/effects';

import { authActions } from '../index';
import changePassword from './changePassword';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import setToken from './setToken';
import signIn from './signIn';

const authSagas = all([
  takeLatest(REHYDRATE, setToken),
  takeLatest(authActions.changePasswordRequest, changePassword),
  takeLatest(authActions.forgotPasswordRequest, forgotPassword),
  takeLatest(authActions.resetPasswordRequest, resetPassword),
  takeLatest(authActions.signInRequest, signIn),
]);

export default authSagas;
