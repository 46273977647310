import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerData } from '../../index';

export interface ICreateManufacturerRequestAction {
  data: {
    logo?: string;
    name: string;
  };
  functions: {
    closeForm: () => void;
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function createRequest(
  draft: Draft<IManufacturerData>,
  _: PayloadAction<ICreateManufacturerRequestAction>,
) {
  draft.create.config.isLoading = true;
}

export default createRequest;
