import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { FaBarcode } from 'react-icons/fa';
import { FiBox, FiCamera, FiGlobe } from 'react-icons/fi';
import { SingleValue } from 'react-select';

import { Formik, FormikProps } from 'formik';
import helpers from 'helpers';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import { v4 as uuidV4 } from 'uuid';
import validators from 'validators';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentSelectSimple, {
  OptionTypeBase,
} from 'components/input/select/Simple';
import ComponentInputSimple from 'components/input/Simple';
import ComponentTextarea from 'components/input/Textarea';
import ComponentImageCropper, {
  IComponentImageCropperRefProps,
} from 'components/modal/Cropper';
import ComponentIsVisible from 'components/utils/IsVisible';

import { imageActions } from 'store/slices/image';
import { manufacturerActions } from 'store/slices/manufacturer';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';

import colors from 'styles/colors';
import Forms from 'styles/forms';

import ProductFormImageItem from './Image';
import {
  AddImageButton,
  DraggableMessage,
  ImageLabelSize,
  ImageLabelSizeContainer,
  Label,
  ThumbnailBody,
  UploadFile,
  WithoutImageMessage,
} from './styles';

interface IUploadImageData {
  uploadRef: React.MutableRefObject<HTMLInputElement>;
}

export interface IProductImageFormData {
  isActive: boolean;
  sort: number;
  url: string;
}

export interface IProductFormData {
  amount?: string;
  description?: string;
  ean: string;
  manufacturer: IComponentSelectProps<number> | null;
  name: string;
  otherInfo?: string;
  sku: string;
}

const initialValues: IProductFormData = {
  name: '',
  amount: '',
  description: '',
  manufacturer: null,
  ean: '',
  otherInfo: '',
  sku: '',
};

const productFormValidateSchema = Yup.object().shape({
  name: Yup.string().required('Informe um nome'),
  manufacturer: Yup.object()
    .shape({
      value: Yup.number(),
    })
    .typeError('Selecione o fabricante'),
});

export interface IProductFormRefProps {
  formik: FormikProps<IProductFormData>;
  setUploadedImages: React.Dispatch<
    React.SetStateAction<IProductImageFormData[]>
  >;
  uploadedImages: IProductImageFormData[];
}

interface IProductFormProps {
  handleClose: () => void;
  isLoading: boolean;
  onSubmit: (data: IProductFormData) => void;
}

const ProductForm: React.ForwardRefRenderFunction<
  IProductFormRefProps,
  IProductFormProps
> = ({ handleClose, isLoading, onSubmit }, ref) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const manufacturerToSelect = useReduxSelector(
    manufacturerSelectors.getAllToSelectList,
  );
  const manufacturerToSelectIsLoading = useReduxSelector(
    manufacturerSelectors.getAllToSelectIsLoading,
  );

  const [uploadedImages, setUploadedImages] = useState<IProductImageFormData[]>(
    [],
  );
  const [isClearDnD, setIsClearDnD] = useState(true);

  const productFormikRef = useRef<FormikProps<IProductFormData>>(
    {} as FormikProps<IProductFormData>,
  );
  const uploadImageEvent = useRef<HTMLInputElement>({} as HTMLInputElement);
  const componentImageCropperRef = useRef<IComponentImageCropperRefProps>(null);

  const loadManufacturersToSelect = useCallback(() => {
    reduxDispatch(
      manufacturerActions.getAllToSelectRequest({
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handleUploadImage = (data: IUploadImageData) => {
    data.uploadRef.current.click();
    data.uploadRef.current.addEventListener('change', () => {
      if (data.uploadRef.current.files) {
        const file = data.uploadRef.current.files.item(0);
        if (file) {
          if (file.size >= 5000000) {
            uploadImageEvent.current.value = '';
            toast.show({
              title: 'Tamanho de imagem inválido',
              description: 'Tamanho máximo da imagem 5MB',
              type: 'error',
            });
            return;
          }

          const fileVerified = validators.fileType(file?.type);
          if (!fileVerified) {
            toast.show({
              title: 'Formato de arquivo inválido',
              description: 'Formatos permitidos: png, jpg e jpeg',
              type: 'error',
            });
            return;
          }

          const reader = new FileReader();
          reader.readAsDataURL(file as File);
          reader.onload = event => {
            componentImageCropperRef.current?.open({
              imageUrl: event.target?.result as string,
            });
          };
        }
      }
    });
  };

  const addImageToUploadedImages = useCallback(
    (imageToAddUrl: string) => {
      setUploadedImages(currentUploadedImages => {
        if (currentUploadedImages.length) {
          const newUploadedImageUrl: IProductImageFormData = {
            isActive: true,
            sort: currentUploadedImages.length + 1,
            url: imageToAddUrl as string,
          };
          const newUploadedImages =
            currentUploadedImages.concat(newUploadedImageUrl);

          return newUploadedImages;
        }
        const newUploadedImageUrl: IProductImageFormData = {
          isActive: true,
          sort: 1,
          url: imageToAddUrl as string,
        };
        const newUploadedImages =
          currentUploadedImages.concat(newUploadedImageUrl);

        return newUploadedImages;
      });
      reduxDispatch(
        imageActions.setClearUrl({
          success() {
            //
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleRemoveImage = useCallback((imageToRemoveUrl?: string) => {
    if (imageToRemoveUrl) {
      setUploadedImages(currentUploadedImages => {
        return currentUploadedImages.filter(
          currentUploadedImage => currentUploadedImage.url !== imageToRemoveUrl,
        );
      });
    }
  }, []);

  const reorder = useCallback(
    (
      imageList: IProductImageFormData[],
      startIndex: number,
      endIndex: number,
    ) => {
      const result = Array.from(imageList);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    },
    [],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      setIsClearDnD(false);
      const images = reorder(
        uploadedImages,
        result.source.index,
        result.destination.index,
      );

      const imagesReordered = images.map((image, position) => ({
        ...image,
        sort: position + 1,
      }));

      setUploadedImages(imagesReordered);

      setTimeout(() => {
        setIsClearDnD(true);
      }, 500);
    },
    [uploadedImages, reorder],
  );

  const screenWidth = useMemo(() => {
    return window.innerWidth;
  }, []);

  useEffect(() => {
    loadManufacturersToSelect();
  }, [loadManufacturersToSelect]);

  useImperativeHandle(ref, () => ({
    formik: productFormikRef.current,
    uploadedImages,
    setUploadedImages,
  }));

  return (
    <Formik
      initialValues={initialValues}
      innerRef={productFormikRef}
      onSubmit={onSubmit}
      validateOnChange={false}
      validationSchema={productFormValidateSchema}
    >
      {({ errors, handleChange, setFieldValue, values }) => (
        <Forms.Content>
          <Forms.Grid marginBottom=".5">
            <ComponentInputSimple
              errorMessage={errors.name}
              hasError={!!errors.name}
              label="Nome do produto"
              onChange={handleChange('name')}
              value={values.name}
            />
          </Forms.Grid>
          <Forms.Grid
            gridTemplateColumns="1fr repeat(2, 10rem)"
            gridTemplateColumnsSmall="repeat(2, 1fr)"
            marginBottom=".5"
          >
            <ComponentSelectSimple
              errorMessage={errors.manufacturer as string}
              gridColumn="1 / 3"
              hasError={!!errors.manufacturer}
              icon={FiBox}
              isClearable
              isLoading={manufacturerToSelectIsLoading}
              label="Fabricante"
              onChange={(option: SingleValue<OptionTypeBase>) => {
                setFieldValue('manufacturer', option);
                handleChange('manufacturer');
              }}
              options={manufacturerToSelect}
              placeholder=""
              value={values.manufacturer}
            />
            <ComponentInputSimple
              errorMessage={errors.ean}
              hasError={!!errors.ean}
              icon={FaBarcode}
              label="Código de barras"
              onChange={handleChange('ean')}
              value={values.ean}
            />
            <ComponentInputSimple
              errorMessage={errors.sku}
              hasError={!!errors.sku}
              icon={FiGlobe}
              label="SKU"
              onChange={handleChange('sku')}
              value={values.sku}
            />
          </Forms.Grid>

          <Forms.Grid marginBottom="1">
            <ComponentTextarea
              errorMessage={errors.description}
              hasError={!!errors.description}
              height="6rem"
              label="Apresentação do produto"
              onChange={handleChange('description')}
              value={values.description}
            />
            <ComponentTextarea
              errorMessage={errors.otherInfo}
              hasError={!!errors.otherInfo}
              height="6rem"
              label="Outras informações"
              onChange={handleChange('otherInfo')}
              value={values.otherInfo}
            />
          </Forms.Grid>

          <ComponentIsVisible when={screenWidth >= 1000}>
            <Forms.Grid
              alignItems="flex-start"
              gridTemplateColumns="1fr 12rem"
              marginBottom=".25"
            >
              <Label>Imagem</Label>
              <AddImageButton
                onClick={() =>
                  handleUploadImage({
                    uploadRef: uploadImageEvent,
                  })
                }
                type="button"
              >
                Selecionar imagem <FiCamera size={18} />
                <UploadFile
                  accept="image/png, image/jpg, image/jpeg"
                  ref={uploadImageEvent}
                  type="file"
                />
              </AddImageButton>
            </Forms.Grid>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable direction="horizontal" droppableId={uuidV4()}>
                {provided => (
                  <>
                    <ThumbnailBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <ComponentIsVisible when={!uploadedImages.length}>
                        <WithoutImageMessage>
                          Nenhuma imagem adicionada
                        </WithoutImageMessage>
                      </ComponentIsVisible>
                      <ComponentIsVisible when={!!uploadedImages.length}>
                        {uploadedImages.map((uploadedImage, index) => (
                          <ProductFormImageItem
                            handleRemoveImage={handleRemoveImage}
                            index={index}
                            isClearDnD={isClearDnD}
                            key={uploadedImage.url}
                            uploadedImage={uploadedImage}
                          />
                        ))}
                        {provided.placeholder}
                      </ComponentIsVisible>
                    </ThumbnailBody>
                    <ImageLabelSizeContainer>
                      <ImageLabelSize>Tamanho máximo 5MB</ImageLabelSize>
                    </ImageLabelSizeContainer>
                  </>
                )}
              </Droppable>
            </DragDropContext>
            <DraggableMessage show={!!uploadedImages.length}>
              Para alterar a ordem das imagens clique e arraste a mesma.
            </DraggableMessage>
          </ComponentIsVisible>

          <Forms.Actions gridTemplateColumns="8rem 12rem">
            <ComponentButtonBase
              backgroundColor={colors.red500}
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancelar
            </ComponentButtonBase>
            <ComponentButtonBase
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
            >
              Confirmar
            </ComponentButtonBase>
          </Forms.Actions>

          <ComponentImageCropper
            ref={componentImageCropperRef}
            refreshUploadedImages={addImageToUploadedImages}
          />
        </Forms.Content>
      )}
    </Formik>
  );
};

export default forwardRef(ProductForm);
