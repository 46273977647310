import { Draft, PayloadAction } from '@reduxjs/toolkit';
import IManufacturerImport from 'models/ManufacturerImport';

import { IManufacturerData } from '../../index';

export interface IImportSpreadsheetManufacturerRequestAction {
  data: {
    manufacturers: IManufacturerImport[];
  };
  functions: {
    error(err: any): void;
    goBack(): void;
    success(message: string): void;
  };
}

function importSpreadsheetRequest(
  draft: Draft<IManufacturerData>,
  _: PayloadAction<IImportSpreadsheetManufacturerRequestAction>,
) {
  draft.importSpreadsheet.config.isLoading = true;
}

export default importSpreadsheetRequest;
