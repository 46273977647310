import fileType from './fileType';
import importFileType from './importFileType';
import onlyNumbers from './onlyNumbers';

const validators = {
  fileType,
  importFileType,
  onlyNumbers,
};

export default validators;
