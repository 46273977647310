import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { FaRegAddressCard } from 'react-icons/fa';
import { FiClipboard, FiList, FiSettings, FiShoppingBag } from 'react-icons/fi';

import pages from 'constants/pages';

import icon from 'assets/logo/icon.png';

import ComponentAsideMenuItem from './Item';
import {
  Aside,
  Content,
  ExternalLink,
  Logo,
  Navigation,
  Thumbnail,
} from './styles';

const ComponentAsideMenu = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateTo = useCallback(
    (page: string) => {
      navigate(page);
    },
    [navigate],
  );
  return (
    <Aside>
      <Content>
        <Thumbnail>
          <Logo alt="Logo Meu Catálogo" src={icon} />
        </Thumbnail>
        <Navigation>
          <ComponentAsideMenuItem
            icon={FiList}
            isSelected={location.pathname.startsWith('/produtos')}
            name="Produtos"
            onClick={() => handleNavigateTo(pages.product.list)}
          />
          <ComponentAsideMenuItem
            icon={FiClipboard}
            isSelected={location.pathname.startsWith('/fabricantes')}
            name="Fabricantes"
            onClick={() => handleNavigateTo(pages.manufacturer.list)}
          />
          <ComponentAsideMenuItem
            icon={FaRegAddressCard}
            isSelected={location.pathname === '/usuarios'}
            name="Usuários"
            onClick={() => handleNavigateTo(pages.user.list)}
          />
          <ComponentAsideMenuItem
            icon={FiSettings}
            isSelected={location.pathname === '/configuracoes'}
            name="Configurações"
            onClick={() => handleNavigateTo(pages.settings)}
          />
          <ExternalLink href={process.env.REACT_APP_WEB_URL} target="_blank">
            <ComponentAsideMenuItem icon={FiShoppingBag} name="Catálogo WEB" />
          </ExternalLink>
        </Navigation>
      </Content>
    </Aside>
  );
};

export default ComponentAsideMenu;
