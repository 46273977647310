import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllManufacturerResponse, {
  IGetAllManufacturerListResponse,
} from 'services/httpClient/responses/manufacturer/IGetAllManufacturerResponse';

import { manufacturerActions } from '../index';
import { IGetAllToSelectManufacturerRequestAction } from '../reducers/getAllToSelect/request';

function* getAllToSelect(
  action: PayloadAction<IGetAllToSelectManufacturerRequestAction>,
) {
  try {
    const response: AxiosResponse<IGetAllManufacturerResponse> = yield call(
      httpClient.get,
      'admins/manufacturers',
    );
    let list: IGetAllManufacturerListResponse[] = [];
    if (response.status === EHttpStatusCodes.OK) {
      list = response.data.list;
    }
    yield put(
      manufacturerActions.getAllToSelectSuccess({
        list,
      }),
    );
  } catch (err) {
    action.payload.functions.error(err);
    yield put(manufacturerActions.getAllToSelectFailure());
  }
}

export default getAllToSelect;
