import { Bar, Container, LoadingBar, Message, Title } from './styles';

const ProductImportWarning = (): JSX.Element => {
  return (
    <Container>
      <Title>Importando produtos</Title>

      <Message>
        Aguarde enquanto a importação dos produtos está sendo realizada.
      </Message>

      <LoadingBar>
        <Bar />
      </LoadingBar>
    </Container>
  );
};

export default ProductImportWarning;
