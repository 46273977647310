import { createSlice } from '@reduxjs/toolkit';
import IPagination from 'interfaces/IPagination';
import IProduct from 'models/Product';

import reducers from './reducers';

export interface IProductData {
  changeStatus: {
    config: {
      isLoading: boolean;
    };
  };
  create: {
    config: {
      isLoading: boolean;
    };
  };
  edit: {
    config: {
      isLoading: boolean;
    };
  };
  getAll: {
    config: {
      emptyMessage: string;
      errorMessage: string;
      isLoading: boolean;
      pagination: IPagination;
    };
    list: IProduct[];
  };
  importSpreadsheet: {
    config: {
      isLoading: boolean;
    };
    generatingInBackground: boolean;
    id: number | null;
  };
  remove: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IProductData = {
  changeStatus: {
    config: {
      isLoading: false,
    },
  },
  create: {
    config: {
      isLoading: false,
    },
  },
  edit: {
    config: {
      isLoading: false,
    },
  },
  getAll: {
    config: {
      isLoading: false,
      emptyMessage: 'Nenhum produto encontrado',
      errorMessage: '',
      pagination: {
        page: 1,
        totalPages: 1,
      },
    },
    list: [],
  },
  importSpreadsheet: {
    config: {
      isLoading: false,
    },
    generatingInBackground: false,
    id: null,
  },
  remove: {
    config: {
      isLoading: false,
    },
  },
};

const productSlice = createSlice({
  name: '@product',
  initialState,
  reducers,
});

export const productActions = productSlice.actions;
export const productReducers = productSlice.reducer;
