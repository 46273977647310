import { useRef } from 'react';

import ComponentInputBase, {
  IComponentInputBaseProps,
  IComponentInputBaseRefProps,
} from '../Base';

type IComponentInputPhoneProps = IComponentInputBaseProps;

const ComponentInputPhone: React.FC<IComponentInputPhoneProps> = props => {
  const componentInputBaseRef = useRef<IComponentInputBaseRefProps>(null);

  return (
    <ComponentInputBase {...props} maxLength={15} ref={componentInputBaseRef} />
  );
};

export default ComponentInputPhone;
