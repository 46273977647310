import { all } from 'redux-saga/effects';

import authSagas from './slices/auth/sagas';
import imageSagas from './slices/image/sagas';
import manufacturerSagas from './slices/manufacturer/sagas';
import productSagas from './slices/product/sagas';
import settingsSagas from './slices/settings/sagas';
import userSagas from './slices/user/sagas';

function* rootSagas() {
  yield all([
    authSagas,
    imageSagas,
    manufacturerSagas,
    productSagas,
    settingsSagas,
    userSagas,
  ]);
}

export default rootSagas;
