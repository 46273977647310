import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  background-color: ${colors.gray900};
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  width: 100%;
`;

export const Content = styled.div`
  background-color: ${colors.white900};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 31.25rem;
  padding: 4rem;
  width: 100%;
`;

export const Logo = styled.img`
  margin: 0 auto 2rem;
  max-width: 10rem;
`;

export const Message = styled.p`
  color: ${colors.gray600};
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;
