import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

export interface ISettingsData {
  edit: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: ISettingsData = {
  edit: {
    config: {
      isLoading: false,
    },
  },
};

const settingsSlice = createSlice({
  name: '@settings',
  initialState,
  reducers,
});

export const settingsActions = settingsSlice.actions;
export const settingsReducers = settingsSlice.reducer;
