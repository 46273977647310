interface IDataUrlToFile {
  dataUrl: string;
  fileName: string;
}

async function dataUrlToFile({
  dataUrl,
  fileName,
}: IDataUrlToFile): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
}

export default dataUrlToFile;
