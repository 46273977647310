import styled, { css } from 'styled-components';

import Tooltip from 'components/utils/Tooltip';

import colors from 'styles/colors';

interface IContainerProps {
  gridColumn?: string;
}

export const Container = styled.div<IContainerProps>`
  grid-column: ${props => (props.gridColumn ? props.gridColumn : 'auto')};
  margin-top: 0.5rem;
  width: 100%;
`;

export const Label = styled.label`
  color: ${colors.gray300};
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`;

interface ISelectContainerProps {
  hasIcon?: boolean;
  height?: string;
  isErrored?: boolean;
  isFocused: boolean;
}

export const SelectContainer = styled.div<ISelectContainerProps>`
  align-items: center;
  background: ${colors.white900};
  border: 1px solid ${colors.gray100};
  border-radius: 0.5rem;
  color: ${colors.gray200};
  display: grid;
  height: ${props => (props.height ? props.height : '2.25rem')};
  padding-left: 0.75rem;
  position: relative;
  width: 100%;
  ${props =>
    props.isErrored &&
    css`
      border-color: ${colors.red500};
      grid-template-columns: ${props.hasIcon ? '1.5rem 1fr 2rem' : ' 1fr 2rem'};
      padding-right: 0.25rem;
    `}

  ${props =>
    props.hasIcon &&
    css`
      grid-template-columns: ${props.isErrored
        ? '1.5rem 1fr 2rem'
        : '1.5rem 1fr'};
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: ${colors.cyan900};
      color: ${colors.cyan900};
    `}
`;

export const Error = styled(Tooltip)``;
