import ComponentIsVisible from 'components/utils/IsVisible';

import notFound from 'assets/utils/empty.svg';

import { Container, EmptyImage, EmptyMessage } from './styles';

interface IComponentEmptyProps {
  message: string;
  show: boolean;
}

const ComponentEmpty = ({
  message,
  show,
}: IComponentEmptyProps): JSX.Element => {
  return (
    <ComponentIsVisible when={show}>
      <Container>
        <EmptyImage src={notFound} />
        <EmptyMessage>{message}</EmptyMessage>
      </Container>
    </ComponentIsVisible>
  );
};

export default ComponentEmpty;
