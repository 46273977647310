import changeStatusIsLoading from './changeStatusIsLoading';
import createIsLoading from './createIsLoading';
import editIsLoading from './editIsLoading';
import getAllEmptyMessage from './getAllEmptyMessage';
import getAllErrorMessage from './getAllErrorMessage';
import getAllIsLoading from './getAllIsLoading';
import getAllList from './getAllList';
import getAllPagination from './getAllPagination';
import importSpreadsheetId from './importSpreadsheetId';
import importSpreadsheetInBackground from './importSpreadsheetInBackground';
import importSpreadsheetIsLoading from './importSpreadsheetIsLoading';
import removeIsLoading from './removeIsLoading';

const productSelectors = {
  changeStatusIsLoading,
  createIsLoading,
  editIsLoading,
  getAllEmptyMessage,
  getAllErrorMessage,
  getAllIsLoading,
  getAllList,
  getAllPagination,
  importSpreadsheetIsLoading,
  importSpreadsheetId,
  importSpreadsheetInBackground,
  removeIsLoading,
};

export default productSelectors;
