import { useCallback, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { FiLock } from 'react-icons/fi';

import { Formik } from 'formik';
import helpers from 'helpers';
import * as Yup from 'yup';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';

import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import authLogo from 'assets/logo/auth-logo.png';

import Forms from 'styles/forms';

import { Container, Content, Logo, Message } from './styles';

interface IResetPasswordFormData {
  confirmNewPassword: string;
  newPassword: string;
}

const initialValues: IResetPasswordFormData = {
  confirmNewPassword: '',
  newPassword: '',
};

const resetPasswordValidateSchema = Yup.object().shape({
  newPassword: Yup.string().trim().required('Informe a nova senha'),
  confirmNewPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], 'Senhas são diferentes')
    .required('Informe a confirmação da nova senha'),
});

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = (): JSX.Element => {
  const toast = useToast();
  const navigate = useNavigate();
  const query = useQuery();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(authSelectors.resetPasswordIsLoading);

  const recoveryToken = query.get('token') as string | undefined;

  const handleResetPassword = useCallback(
    (data: IResetPasswordFormData) => {
      reduxDispatch(
        authActions.resetPasswordRequest({
          data: {
            newPassword: data.newPassword,
            recoveryToken: recoveryToken as string,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            navigateTo: () => {
              navigate(pages.auth.signIn, { replace: true });
            },
            success: (message: string) => {
              toast.show({
                title: message,
                type: 'success',
              });
            },
          },
        }),
      );
    },
    [navigate, reduxDispatch, toast, recoveryToken],
  );
  return (
    <Container>
      <Content>
        <Logo alt="Logomarca do site" src={authLogo} />

        <Message>
          Preencha os campos abaixo para criar uma nova senha de acesso
        </Message>

        <Formik
          initialValues={initialValues}
          onSubmit={handleResetPassword}
          validateOnChange={false}
          validationSchema={resetPasswordValidateSchema}
        >
          {({ errors, handleChange, values }) => (
            <Forms.Content>
              <ComponentInputPassword
                errorMessage={errors.newPassword}
                hasError={!!errors.newPassword}
                height="2.75rem"
                icon={FiLock}
                onChange={handleChange('newPassword')}
                placeholder="Nova senha"
                value={values.newPassword}
              />

              <ComponentInputPassword
                errorMessage={errors.confirmNewPassword}
                hasError={!!errors.confirmNewPassword}
                height="2.75rem"
                icon={FiLock}
                onChange={handleChange('confirmNewPassword')}
                placeholder="Confirmar nova senha"
                value={values.confirmNewPassword}
              />

              <ComponentButtonBase
                disabled={isLoading}
                height="2.5rem"
                isLoading={isLoading}
                type="submit"
              >
                Cadastrar nova senha
              </ComponentButtonBase>
            </Forms.Content>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default ResetPassword;
