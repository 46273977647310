import styled, { css } from 'styled-components';

import colors from './colors';

const Root = styled.div`
  display: grid;
  position: relative;
  width: 100%;
`;

interface IMainProps {
  isSigned?: boolean;
}

const Main = styled.main<IMainProps>`
  display: inline-flex;
  width: auto;
  @media screen and (min-width: 1240px) {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  ${props =>
    props.isSigned &&
    css`
      padding: 1rem 1rem 1rem 10.25rem;
    `}
`;

const Global = styled.div`
  background-color: ${colors.white900};
  border: 1px solid ${colors.gray100};
  border-radius: 1rem;
  min-height: calc(100vh - 2rem);
  overflow-x: auto;
  padding: 2rem;
  position: relative;
  width: 100%;
  @media (min-width: 900px) {
    padding: 2rem 2.25rem;
  }
`;

const Content = styled.div`
  height: fit-content;
  max-width: 70rem;
  position: relative;
  width: 100%;
  @media screen and (min-width: 1440px) {
    max-width: 75rem;
  }
  @media screen and (min-width: 1600px) {
    max-width: 90rem;
  }
  @media screen and (min-width: 1920px) {
    max-width: 100rem;
  }
`;

export default {
  Root,
  Main,
  Global,
  Content,
};
