import styled from 'styled-components';

import animations from 'styles/animations';
import colors from 'styles/colors';

interface IContentProps {
  maxWidth?: number;
}

export const Content = styled.div.attrs({
  id: 'modalContent',
})<IContentProps>`
  animation-delay: 0.1s;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-name: ${animations.appear};
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: ${colors.white900};
  border-radius: 0.375rem;
  max-height: 90%;
  max-width: ${props => (props.maxWidth ? props.maxWidth : 28)}rem;
  overflow-y: auto;
  padding: 2rem;
  position: relative;
  width: 100%;
  z-index: 6;
  &.hideModal {
    animation-name: ${animations.disappear};
  }
`;

export const Title = styled.h5`
  color: ${colors.orange900};
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

export const SubTitle = styled.p`
  color: ${colors.gray200};
  font-size: 0.875rem;
`;
