import styled from 'styled-components';

import colors from 'styles/colors';

export const SettingsContent = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 30rem 1fr;
  margin-top: 1.5rem;
`;

export const Fields = styled.div``;

export const PdfLabel = styled.label`
  color: ${colors.gray300};
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`;

export const ImageContent = styled.div``;

export const ImageLabel = styled.label`
  color: ${colors.gray300};
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export const UploadFile = styled.input`
  display: none;
`;

export const Thumbnail = styled.div`
  align-items: center;
  border-radius: 0.375rem;
  display: flex;
  height: 9rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 9rem;
`;

export const PreviewImage = styled.img`
  border: 1px solid ${colors.gray100};
  border-radius: 0.375rem;
  height: 100%;
  width: 100%;
`;

export const AddImageButton = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  border-radius: 0.375rem;
  color: ${colors.cyan900};
  column-gap: 1rem;
  display: flex;
  font-size: 0.75rem;
  justify-content: center;
  margin-top: 1rem;
  text-decoration: underline;
  width: 9rem;

  &:hover {
    opacity: 0.6;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-right: auto;
  margin-top: 0.5rem;
  width: 14rem;
`;
