import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IUserData } from '../../index';

export interface IChangeStatusUserSuccessAction {
  id: number;
}

function changeStatusSuccess(
  draft: Draft<IUserData>,
  action: PayloadAction<IChangeStatusUserSuccessAction>,
) {
  draft.changeStatus.config.isLoading = false;
  const userIndex = draft.getAll.list.findIndex(
    currentUser => currentUser.id === action.payload.id,
  );
  const userToChangeStatus = draft.getAll.list[userIndex];
  const userChangedStatus = {
    ...userToChangeStatus,
    isActive: !userToChangeStatus.isActive,
  };
  draft.getAll.list[userIndex] = userChangedStatus;
}

export default changeStatusSuccess;
