import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IGetAllManufacturerListResponse } from 'services/httpClient/responses/manufacturer/IGetAllManufacturerResponse';

import { IManufacturerData } from '../../index';

export interface IGetAllManufacturerSuccessWithDataAction {
  list: IGetAllManufacturerListResponse[];
  totalPages: number;
}

function getAllSuccessWithData(
  draft: Draft<IManufacturerData>,
  action: PayloadAction<IGetAllManufacturerSuccessWithDataAction>,
) {
  draft.getAll.config.errorMessage = '';
  draft.getAll.config.emptyMessage = '';
  draft.getAll.config.isLoading = false;
  draft.getAll.config.pagination.totalPages = action.payload.totalPages;
  draft.getAll.list = action.payload.list;
}

export default getAllSuccessWithData;
