/* eslint-disable import/no-duplicates */
import { addMinutes, format, parseISO } from 'date-fns';
import prBrLocale from 'date-fns/locale/pt-BR';

function date(value: string | Date, mask = 'dd/MM/yyyy'): string {
  if (typeof value === 'string') {
    const valueNormalized = addMinutes(
      parseISO(value),
      parseISO(value).getTimezoneOffset(),
    );
    const valueParsed = format(valueNormalized, mask, {
      locale: prBrLocale,
    });
    return valueParsed;
  }
  const valueParsed = format(value, mask);
  return valueParsed;
}

export default date;
