import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

interface IImportSpreadsheetProductData {
  apresentacao?: string;
  codigo_barras?: string;
  fabricante: string;
  nome_produto: string;
  outras_informacoes?: string;
  sku?: string;
}

export interface IImportSpreadsheetProductRequestAction {
  data: {
    products: IImportSpreadsheetProductData[];
  };
  functions: {
    error(err: any): void;
    goBack(): void;
    success(message: string): void;
  };
}

function importSpreadsheetRequest(
  draft: Draft<IProductData>,
  _: PayloadAction<IImportSpreadsheetProductRequestAction>,
) {
  draft.importSpreadsheet.config.isLoading = true;
}

export default importSpreadsheetRequest;
