import ComponentInputBase, {
  IComponentInputBaseProps,
} from 'components/input/Base';

type IComponentInputPasswordProps = IComponentInputBaseProps;

const ComponentInputPassword: React.FC<
  IComponentInputPasswordProps
> = props => {
  return <ComponentInputBase secure type="password" {...props} />;
};

export default ComponentInputPassword;
