import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  column-gap: 0.25rem;
  display: flex;
  flex-direction: row;
  font-size: 0.8rem;
`;

export const Radio = styled.label`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  color: ${colors.gray500};
  cursor: pointer;
  display: flex;
  height: 1.5rem;
  justify-content: flex-start;
  margin-top: 0;
  padding-left: 2rem;
  position: relative;
  user-select: none;
  width: 1.5rem;
  &:hover .radioInput ~ .circlemark {
    background-color: ${colors.cyan900};
    opacity: 0.7;
  }
`;

export const Input = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease-in;
  width: 0;
  &:checked ~ .circlemark {
    background-color: ${colors.cyan900};
  }
  &:checked ~ .circlemark:after {
    opacity: 1;
  }
`;

export const Mark = styled.span`
  background-color: ${colors.transparent};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 1.35rem;
  left: 0;
  position: absolute;
  transition: all 0.3s ease-in;
  width: 1.35rem;
  &:after {
    background-color: ${colors.white900};
    border-radius: 50%;
    content: '';
    height: 0.6rem;
    left: 0.3rem;
    opacity: 0;
    position: absolute;
    top: 0.3rem;
    width: 0.6rem;
  }
`;
