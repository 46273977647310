import { Draggable } from 'react-beautiful-dnd';
import { FiTrash2 } from 'react-icons/fi';

import { IProductImageFormData } from '../index';
import {
  DraggableContent,
  PreviewImage,
  RemoveButton,
  Thumbnail,
} from '../styles';

interface IProductFormImageItemProps {
  handleRemoveImage: (imageToRemoveUrl: string) => void;
  index: number;
  isClearDnD: boolean;
  uploadedImage: IProductImageFormData;
}

const ProductFormImageItem = ({
  handleRemoveImage,
  index,
  isClearDnD,
  uploadedImage,
}: IProductFormImageItemProps): JSX.Element => {
  return (
    <Draggable
      draggableId={uploadedImage.url}
      index={index}
      isDragDisabled={!isClearDnD}
      key={uploadedImage.url}
    >
      {(provided, snapshot) => (
        <DraggableContent
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Thumbnail isDragging={snapshot.isDragging}>
            <PreviewImage src={uploadedImage.url} />
            <RemoveButton
              onClick={() => handleRemoveImage(uploadedImage.url)}
              type="button"
            >
              <FiTrash2 size={18} />
            </RemoveButton>
          </Thumbnail>
        </DraggableContent>
      )}
    </Draggable>
  );
};

export default ProductFormImageItem;
