import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient/index';
import IGetAllUserRequest from 'services/httpClient/request/user/IGetAllUserRequest';
import IGetAllUserResponse from 'services/httpClient/responses/user/IGetAllUserResponse';

import { userActions } from '../index';
import { IGetAllUserRequestAction } from '../reducers/getAll/request';

function* getAll(action: PayloadAction<IGetAllUserRequestAction>) {
  try {
    const params: IGetAllUserRequest = {
      name: action.payload.data.name || undefined,
      page: action.payload.data.page,
    };
    const response: AxiosResponse<IGetAllUserResponse> = yield call(
      httpClient.get,
      'admins/users',
      { params },
    );
    if (response.status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        userActions.getAllSuccessWithoutData({
          message: 'Nenhum usuário encontrado',
        }),
      );
      return;
    }
    yield put(
      userActions.getAllSuccessWithData({
        list: response.data.list,
        totalPages: response.data.totalPages || 1,
      }),
    );
  } catch (err) {
    action.payload.functions.error(err);
    yield put(userActions.getAllFailure());
  }
}

export default getAll;
