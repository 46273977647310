import cnpj from './cnpj';
import cpf from './cpf';
import cpfOrCnpj from './cpfOrCnpj';
import date from './date';
import importSpreadsheet from './importSpreadsheet';
import money from './money';
import onlyNumbers from './onlyNumbers';
import phone from './phone';
import postalCode from './postalCode';
import removeMask from './removeMask';

export default {
  cpf,
  cnpj,
  cpfOrCnpj,
  onlyNumbers,
  phone,
  postalCode,
  importSpreadsheet,
  removeMask,
  money,
  date,
};
