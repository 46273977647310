import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

export interface IRemoveProductSuccessAction {
  id: number;
}

function removeSuccess(
  draft: Draft<IProductData>,
  action: PayloadAction<IRemoveProductSuccessAction>,
) {
  draft.remove.config.isLoading = false;
  draft.getAll.list = draft.getAll.list.filter(
    product => product.id !== action.payload.id,
  );
}

export default removeSuccess;
