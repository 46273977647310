interface IImportSpreadsheetOptions {
  defaultValue?: string;
}

function importSpreadsheet(
  value: string | null,
  options?: IImportSpreadsheetOptions,
): string {
  if (!value) {
    return options?.defaultValue || '';
  }

  const valueFormatted = value
    .toLowerCase()
    .replace(/('\\s', 'g')/, '')
    .replace(/('[àáâãäå]', 'g')/, 'a')
    .replace(/('æ', 'g')/, 'ae')
    .replace(/('ç', 'g')/, 'c')
    .replace(/('[èéêë]', 'g')/, 'e')
    .replace(/('[ìíîï]', 'g')/, 'i')
    .replace(/('ñ', 'g')/, 'n')
    .replace(/('[òóôõö]', 'g')/, 'o')
    .replace(/('œ', 'g')/, 'oe')
    .replace(/('[ùúûü]', 'g')/, 'u')
    .replace(/('[ýÿ]', 'g')/, 'y')
    .replace(/('\\W', 'g')/, '');

  return valueFormatted;
}

export default importSpreadsheet;
