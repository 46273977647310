import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FormikProps } from 'formik';
import helpers from 'helpers';
import IManufacturer from 'models/Manufacturer';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import imageSelectors from 'store/slices/image/selectors';
import { manufacturerActions } from 'store/slices/manufacturer';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';

import ManufacturerForm, { IManufacturerFormData } from '../Form';

export interface IManufacturerEditRefProps {
  open(data: IManufacturer): void;
}

const ManufacturerEdit: React.ForwardRefRenderFunction<
  IManufacturerEditRefProps
> = (_, ref) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(manufacturerSelectors.editIsLoading);
  const uploadedImageUrl = useReduxSelector(imageSelectors.uploadUrl);

  const [hideModal, setHideModal] = useState<boolean>(false);
  const [manufacturerValues, setManufacturerValues] = useState<IManufacturer>(
    {} as IManufacturer,
  );

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const manufacturerFormRef = useRef<FormikProps<IManufacturerFormData>>(null);

  const openModal = useCallback((data: IManufacturer) => {
    setHideModal(false);
    setManufacturerValues(data);
    componentModalBaseRef.current?.open();

    setTimeout(() => {
      manufacturerFormRef.current?.setValues({
        name: data.name,
        logo: data.logo,
      });
    }, 250);
  }, []);
  const closeModal = useCallback(() => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleEditManufacturer = useCallback(
    async (data: IManufacturerFormData) => {
      manufacturerFormRef.current?.setErrors({});
      reduxDispatch(
        manufacturerActions.editRequest({
          data: {
            id: manufacturerValues.id,
            name: data.name,
            logo: data.logo || uploadedImageUrl,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            closeForm: () => {
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, manufacturerValues, reduxDispatch, toast, uploadedImageUrl],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''}>
        <Title>Editar fabricante</Title>
        <ManufacturerForm
          handleClose={closeModal}
          isLoading={isLoading}
          onSubmit={handleEditManufacturer}
          ref={manufacturerFormRef}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ManufacturerEdit);
