import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

interface ISignInSuccessAction {
  token: string;
}

function signInSuccess(
  draft: Draft<IAuthData>,
  action: PayloadAction<ISignInSuccessAction>,
) {
  draft.config.isLoading = false;
  draft.authorization.token = action.payload.token;
}

export default signInSuccess;
