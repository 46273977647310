import { AxiosInstance } from 'axios';

function setToken(httpClient: AxiosInstance): void {
  httpClient.interceptors.response.use(success => {
    if (success.data.token) {
      httpClient.defaults.headers.common.Authorization = `Bearer ${success.data.token}`;
    }
    return success;
  });
}

export default setToken;
