import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { ReactCropperElement, ReactCropperProps } from 'react-cropper';

import helpers from 'helpers';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentIsVisible from 'components/utils/IsVisible';

import { imageActions } from 'store/slices/image';
import imageSelectors from 'store/slices/image/selectors';

import colors from 'styles/colors';

import 'cropperjs/dist/cropper.css';

import ComponentBackdrop from '../Backdrop';
import {
  Actions,
  Container,
  Content,
  ContentPreview,
  PreviewImage,
  Title,
} from './styles';

interface IOpenCropperData {
  imageUrl: string;
}

export interface IComponentImageCropperRefProps {
  open(data: IOpenCropperData): void;
}

interface IComponentImageCropperProps extends ReactCropperProps {
  refreshUploadedImages?: (imageToAddUrl: string) => void;
}

const ComponentImageCropper: React.ForwardRefRenderFunction<
  IComponentImageCropperRefProps,
  IComponentImageCropperProps
> = (
  { aspectRatio, initialAspectRatio = 1 / 1, refreshUploadedImages },
  ref,
) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(imageSelectors.uploadIsLoading);

  const [hideModal, setHideModal] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>('');

  const previewImageRef = useRef<ReactCropperElement>(
    {} as ReactCropperElement,
  );

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const openModal = useCallback((data: IOpenCropperData) => {
    setIsOpened(true);
    setHideModal(false);
    setImageSrc(data.imageUrl);
  }, []);

  const closeModal = useCallback(() => {
    setHideModal(true);
    setImageSrc('');
    const animated = document.getElementById('modalCropperContent');
    if (animated) {
      animated.addEventListener('animationend', () => {
        setIsOpened(false);
      });
    }
  }, []);

  const handleCropImage = useCallback(() => {
    const { cropper } = previewImageRef.current;

    if (cropper) {
      const imgFile = cropper.getCroppedCanvas().toDataURL();
      setImageSrc(imgFile);
    }
  }, []);

  const handleUploadImage = useCallback(async () => {
    const blobImage = await helpers.dataUrlToFile({
      dataUrl: imageSrc,
      fileName: 'file.png',
    });

    reduxDispatch(
      imageActions.uploadRequest({
        data: {
          file: blobImage,
        },
        functions: {
          error(err: any) {
            helpers.errorHandling(err);
          },
          success(message: string) {
            toast.show({
              title: message,
              type: 'success',
            });
          },
          close(uploadedUrl: string) {
            if (refreshUploadedImages) {
              refreshUploadedImages(uploadedUrl);
            }
            closeModal();
          },
        },
      }),
    );
  }, [closeModal, imageSrc, reduxDispatch, refreshUploadedImages, toast]);

  const body = document.getElementById('mainBody');

  useEffect(() => {
    if (body && isOpened) {
      body.classList.add('overflowHidden');
    }
    return () => {
      body?.classList.remove('overflowHidden');
    };
  }, [body, isOpened]);

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentIsVisible when={isOpened}>
      <Container>
        <ComponentBackdrop />
        <Content className={hideModal ? 'hideCropperModal' : ''} maxWidth={40}>
          <Title>Recortar imagem</Title>

          <ContentPreview>
            <PreviewImage
              aspectRatio={aspectRatio}
              background={false}
              draggable={false}
              guides={false}
              initialAspectRatio={initialAspectRatio}
              ref={previewImageRef}
              src={imageSrc}
              toggleDragModeOnDblclick={false}
              viewMode={2}
            />
          </ContentPreview>

          <Actions>
            <ComponentButtonBase
              backgroundColor={colors.red500}
              disabled={isLoading}
              onClick={closeModal}
              type="button"
            >
              Cancelar
            </ComponentButtonBase>

            <ComponentButtonBase
              disabled={isLoading}
              onClick={handleCropImage}
              type="button"
            >
              Cortar imagem
            </ComponentButtonBase>

            <ComponentButtonBase
              backgroundColor={colors.orange900}
              disabled={isLoading}
              isLoading={isLoading}
              onClick={handleUploadImage}
              type="button"
            >
              Confirmar
            </ComponentButtonBase>
          </Actions>
        </Content>
      </Container>
    </ComponentIsVisible>
  );
};

export default forwardRef(ComponentImageCropper);
