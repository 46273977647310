import styled from 'styled-components';

import Tables from 'styles/tables';

export const UserItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: repeat(2, 20rem) 7rem 7rem 4rem 6rem;
  padding: 1.25rem;

  @media screen and (min-width: 1240px) {
    grid-template-columns: repeat(2, 1fr) 10rem 9rem repeat(2, 8rem);
  }
`;

export const UserItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: repeat(2, 20rem) 7rem 7rem 4rem 6rem;
  padding: 1rem 1.25rem;

  @media screen and (min-width: 1240px) {
    grid-template-columns: repeat(2, 1fr) 10rem 9rem repeat(2, 8rem);
  }
`;
