import { useCallback, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';

import helpers from 'helpers';
import IManufacturerImport from 'models/ManufacturerImport';
import readXlsxFile from 'read-excel-file';
import validators from 'validators';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentHeader from 'components/Header';
import ComponentEmpty from 'components/utils/Empty';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import { manufacturerActions } from 'store/slices/manufacturer';

import spreadsheetModal from 'assets/csvs/modelo_importacao_fabricantes.xlsx';

import colors from 'styles/colors';
import Containers from 'styles/containers';
import Tables from 'styles/tables';

import ManufacturerImportItem from './Item';
import {
  Actions,
  DownloadSpreadsheetModel,
  ListType,
  ListTypes,
  Row,
  UploadActions,
  UploadFile,
} from './styles';

enum EListType {
  Invalid = 'Invalid',
  Valid = 'Valid',
}

interface IImportSpreadsheet {
  uploadRef: React.MutableRefObject<HTMLInputElement>;
}

export interface IManufacturerImportWithSelected extends IManufacturerImport {
  selected: boolean;
}

const ManufacturerImport = (): JSX.Element => {
  const toast = useToast();
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();

  const [listSelected, setListSelected] = useState<EListType>(EListType.Valid);
  const [isImportManufacturerLoading, setIsImportManufacturerLoading] =
    useState<boolean>(false);
  const [manufacturersValid, setManufacturersValid] = useState<
    IManufacturerImportWithSelected[]
  >([]);
  const [manufacturersInvalid, setManufacturersInvalid] = useState<
    IManufacturerImportWithSelected[]
  >([]);

  const listRef = useRef<List>(null);
  const uploadSpreadsheetInputRef = useRef<HTMLInputElement>(
    {} as HTMLInputElement,
  );

  const handleChangeListType = useCallback((listType: EListType) => {
    setListSelected(listType);
  }, []);

  const handleReadFile = useCallback(
    (data: IImportSpreadsheet) => {
      data.uploadRef.current.click();

      data.uploadRef.current.addEventListener('change', async () => {
        if (data.uploadRef.current.files) {
          const file = data.uploadRef.current.files.item(0);
          if (file) {
            const fileVerified = validators.importFileType(file?.type);

            if (!fileVerified) {
              toast.show({
                title: 'Formato de arquivo inválido',
                description: 'Formatos permitidos: .xlsx, .xls e .csv',
                type: 'error',
              });
              return;
            }

            const element = document.getElementById('importList');
            window.scroll(0, element?.offsetTop || 0);
            setIsImportManufacturerLoading(true);

            const rowsRead = await readXlsxFile(file);
            rowsRead.shift();

            const manufacturersFormattedValid = [];
            const manufacturersFormattedNotValid = [];

            for (let row = 0; row < rowsRead.length; row += 1) {
              const manufacturerFormatted = {
                nome: rowsRead[row][0]?.toString().toUpperCase() || undefined,
                selected: true,
              } as IManufacturerImportWithSelected;

              if (
                manufacturerFormatted.nome === undefined ||
                manufacturerFormatted.nome.length > 100 ||
                manufacturerFormatted.nome.length < 1
              ) {
                manufacturersFormattedNotValid.push(manufacturerFormatted);
              } else {
                manufacturersFormattedValid.push(manufacturerFormatted);
              }
            }

            setManufacturersValid(manufacturersFormattedValid);
            setManufacturersInvalid(manufacturersFormattedNotValid);

            setIsImportManufacturerLoading(false);
          }
        }
      });
    },
    [toast],
  );

  const handleToggleSelected = useCallback(
    (index: number) => {
      manufacturersValid[index].selected = !manufacturersValid[index].selected;
      listRef.current?.forceUpdateGrid();
    },
    [manufacturersValid],
  );

  const handleSendProduts = useCallback(() => {
    const manufacturersSelected = manufacturersValid.filter(
      manufacturerValid => manufacturerValid.selected,
    );

    const manufacturersFormatted: IManufacturerImport[] =
      manufacturersSelected.map(manufacturer => ({
        nome: manufacturer.nome.toUpperCase(),
      }));

    reduxDispatch(
      manufacturerActions.importSpreadsheetRequest({
        data: {
          manufacturers: manufacturersFormatted,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
          goBack: () => {
            navigate(pages.manufacturer.list, { replace: true });
          },
          success: (message: string) => {
            setManufacturersInvalid([]);
            setManufacturersValid([]);
            toast.show({
              title: message,
              type: 'success',
            });
            listRef.current?.forceUpdateGrid();
          },
        },
      }),
    );
  }, [manufacturersValid, navigate, reduxDispatch, toast]);

  const MemoizedComponentEmpty = useCallback(
    () => (
      <ComponentEmpty
        message="Selecione um arquivo para carregar as informações"
        show={!manufacturersValid.length && !manufacturersInvalid.length}
      />
    ),
    [manufacturersInvalid.length, manufacturersValid.length],
  );

  const MemoizedManufacturerItemBody = useCallback(
    ({ index, key, style }) => (
      <ManufacturerImportItem.Body
        handleToggleSelected={handleToggleSelected}
        index={index}
        isInvalid={listSelected === EListType.Invalid}
        key={key}
        manufacturers={
          listSelected === EListType.Valid
            ? manufacturersValid
            : manufacturersInvalid
        }
        style={style}
      />
    ),
    [
      handleToggleSelected,
      listSelected,
      manufacturersInvalid,
      manufacturersValid,
    ],
  );

  return (
    <Containers.Global>
      <ComponentHeader hasBackButton pageTitle="Importar fabricantes" />
      <Containers.Content>
        <Row>
          <ListTypes>
            <ListType
              isSelected={listSelected === EListType.Valid}
              onClick={() => handleChangeListType(EListType.Valid)}
            >
              {`Válidos (${manufacturersValid.length})`}
            </ListType>
            <ListType
              invalid
              isSelected={listSelected === EListType.Invalid}
              onClick={() => handleChangeListType(EListType.Invalid)}
            >
              {`Inválidos (${manufacturersInvalid.length})`}
            </ListType>
          </ListTypes>
          <UploadActions>
            <DownloadSpreadsheetModel
              download="modelo-planilha.xlsx"
              href={spreadsheetModal}
              rel="noreferrer"
              target="_blank"
            >
              <ComponentButtonBase backgroundColor={colors.gray600}>
                Baixar modelo
              </ComponentButtonBase>
            </DownloadSpreadsheetModel>
            <ComponentButtonBase
              disabled={isImportManufacturerLoading}
              isLoading={isImportManufacturerLoading}
              onClick={() =>
                handleReadFile({
                  uploadRef: uploadSpreadsheetInputRef,
                })
              }
            >
              Selecionar arquivo
              <UploadFile
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ref={uploadSpreadsheetInputRef}
                type="file"
              />
            </ComponentButtonBase>
          </UploadActions>
        </Row>

        <Tables.Items id="importList">
          <ManufacturerImportItem.Header
            isInvalid={listSelected === EListType.Invalid}
          />

          <ComponentLoadingList show={isImportManufacturerLoading} />

          <ComponentIsVisible when={!isImportManufacturerLoading}>
            <ComponentIsVisible when={listSelected === EListType.Valid}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    className="virtualizedListScrollBar"
                    height={50 * 10}
                    noRowsRenderer={MemoizedComponentEmpty}
                    overscanRowCount={10}
                    ref={listRef}
                    rowCount={manufacturersValid.length}
                    rowHeight={80}
                    rowRenderer={MemoizedManufacturerItemBody}
                    width={width}
                  />
                )}
              </AutoSizer>

              <Actions>
                <ComponentButtonBase
                  backgroundColor={colors.orange900}
                  disabled={!manufacturersValid.length}
                  onClick={handleSendProduts}
                >
                  Confirmar importação
                </ComponentButtonBase>
              </Actions>
            </ComponentIsVisible>

            <ComponentIsVisible when={listSelected === EListType.Invalid}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    className="virtualizedListScrollBar"
                    height={50 * 10}
                    noRowsRenderer={MemoizedComponentEmpty}
                    overscanRowCount={10}
                    ref={listRef}
                    rowCount={manufacturersInvalid.length}
                    rowHeight={80}
                    rowRenderer={MemoizedManufacturerItemBody}
                    width={width}
                  />
                )}
              </AutoSizer>
            </ComponentIsVisible>
          </ComponentIsVisible>
        </Tables.Items>
      </Containers.Content>
    </Containers.Global>
  );
};

export default ManufacturerImport;
