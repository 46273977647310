import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../index';

export interface ISignInRequestAction {
  data: {
    email: string;
    password: string;
  };
  functions: {
    error: (err: any) => void;
    navigateTo: () => void;
  };
}

function signInRequest(
  draft: Draft<IAuthData>,
  _: PayloadAction<ISignInRequestAction>,
) {
  draft.config.isLoading = true;
}

export default signInRequest;
