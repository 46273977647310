import {
  FiCheckCircle,
  FiEdit,
  FiLock,
  FiSlash,
  FiXCircle,
} from 'react-icons/fi';

import EUserTypes from 'enums/userTypes';
import IUser from 'models/User';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { UserItemsBody } from '../styles';

interface IItemBodyProps {
  openChangePassword: (data: IUser) => void;
  openChangeStatusAlert: (data: IUser) => void;
  openEdit: (data: IUser) => void;
  position: number;
  user: IUser;
}

const ItemBody = ({
  openChangePassword,
  openChangeStatusAlert,
  openEdit,
  position,
  user,
}: IItemBodyProps): JSX.Element => {
  return (
    <UserItemsBody addColorRow={!!(position % 2)}>
      <Tables.ItemBody>{user.name}</Tables.ItemBody>
      <Tables.ItemBody>{user.email}</Tables.ItemBody>
      <Tables.ItemBody>{user.phone}</Tables.ItemBody>
      <Tables.ItemBody center>
        <ComponentIsVisible when={user.type === EUserTypes.Admin}>
          <Tables.ItemTag bgColor={colors.gray900}>Admin</Tables.ItemTag>
        </ComponentIsVisible>
        <ComponentIsVisible when={user.type === EUserTypes.Preview}>
          <Tables.ItemTag bgColor={colors.gray600}>Visualização</Tables.ItemTag>
        </ComponentIsVisible>
        <ComponentIsVisible when={user.type === EUserTypes.Integration}>
          <Tables.ItemTag bgColor={colors.cyan900}>Integração</Tables.ItemTag>
        </ComponentIsVisible>
      </Tables.ItemBody>
      <Tables.ItemBody center>
        <ComponentIsVisible when={user.isActive}>
          <FiCheckCircle color={colors.orange900} size={18} title="Ativo" />
        </ComponentIsVisible>
        <ComponentIsVisible when={!user.isActive}>
          <FiXCircle color={colors.red500} size={18} title="Desativado" />
        </ComponentIsVisible>
      </Tables.ItemBody>
      <Tables.ItemBody center>
        <Tables.ItemActions>
          <Tables.ItemAction
            onClick={() => openEdit(user)}
            title="Editar usuário"
          >
            <FiEdit color={colors.cyan900} size={18} />
          </Tables.ItemAction>
          <Tables.ItemAction
            onClick={() => openChangePassword(user)}
            title="Alterar senha do usuário"
          >
            <FiLock color={colors.orange700} size={18} />
          </Tables.ItemAction>
          <Tables.ItemAction onClick={() => openChangeStatusAlert(user)}>
            <ComponentIsVisible when={user.isActive}>
              <FiSlash
                color={colors.red500}
                size={18}
                title="Desativar usuário"
              />
            </ComponentIsVisible>
            <ComponentIsVisible when={!user.isActive}>
              <FiCheckCircle
                color={colors.green900}
                size={18}
                title="Ativar usuário"
              />
            </ComponentIsVisible>
          </Tables.ItemAction>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </UserItemsBody>
  );
};

export default ItemBody;
