import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

export interface IImageData {
  upload: {
    config: {
      isLoading: boolean;
    };
    url: string | null;
  };
}

const initialState: IImageData = {
  upload: {
    config: {
      isLoading: false,
    },
    url: null,
  },
};

const imageSlice = createSlice({
  name: '@image',
  initialState,
  reducers,
});

export const imageActions = imageSlice.actions;
export const imageReducers = imageSlice.reducer;
