import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient/index';
import IGetAllManufacturerRequest from 'services/httpClient/request/manufacturer/IGetAllManufacturerRequest';
import IGetAllManufacturerResponse from 'services/httpClient/responses/manufacturer/IGetAllManufacturerResponse';

import { manufacturerActions } from '../index';
import { IGetAllManufacturerRequestAction } from '../reducers/getAll/request';

function* getAll(action: PayloadAction<IGetAllManufacturerRequestAction>) {
  try {
    const params: IGetAllManufacturerRequest = {
      name: action.payload.data.name || undefined,
      page: action.payload.data.page,
    };
    const response: AxiosResponse<IGetAllManufacturerResponse> = yield call(
      httpClient.get,
      'admins/manufacturers',
      { params },
    );
    if (response.status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        manufacturerActions.getAllSuccessWithoutData({
          message: 'Nenhum fabricante encontrado',
        }),
      );
      return;
    }
    yield put(
      manufacturerActions.getAllSuccessWithData({
        list: response.data.list,
        totalPages: response.data.totalPages || 1,
      }),
    );
  } catch (err) {
    action.payload.functions.error(err);
    yield put(manufacturerActions.getAllFailure());
  }
}

export default getAll;
